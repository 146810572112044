import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { marked } from "marked";
import { unescape } from "validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Container,
  Row,
  Breadcrumb,
  Dropdown,
  Col,
  Button,
  ButtonGroup,
  ListGroup,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  faClipboard,
  faRedo,
  faPaperclip,
  faImage,
  faFile,
  faUserCircle,
  faRobot,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
import { localData, runData } from "../context/processor";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import Modal from "react-modal";
import DOMPurify from "dompurify";
import Restricted from "../includes/Restricted";
import Processing from "../includes/Processing";
import { useReport } from "../context/ReportModal";

Modal.setAppElement("#root");

marked.setOptions({
  gfm: true,
  tables: true,
  highlight: function (code, lang) {
    return hljs.highlightAuto(code, [lang]).value;
  },
});

/**
 * ChatPage component that handles user interaction with a chat interface, including message history,
 * instructions customization, and context settings.
 * @component
 * @returns {JSX.Element} - The chat page component.
 */
const ChatPage = () => {
  const [loading, setLoading] = useState(true);
  const [restricted, setRestricted] = useState(false);
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState(
    localData("get", "chatMessages") || []
  );
  const endOfList = useRef(null);
  const [running, setRunning] = useState(false);
  const [typing, setTyping] = useState(false);
  const [instruction, setInstruction] = useState(
    localData("get", "instruction") || ""
  );
  const [contextQuestions, setContextQuestions] = useState(
    localData("get", "contextQuestions") || 3
  );
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [showContextModal, setShowContextModal] = useState(false);

  const { setReport } = useReport();

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    document.querySelectorAll("pre code").forEach((block) => {
      hljs.highlightElement(block);
    });
    if (endOfList.current) {
      endOfList.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    localData("save", "chatMessages", messages);
  }, [messages]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setReport({
          show: true,
          message: "Copying to clipboard was successful!",
          type: "info",
        });
      },
      (err) => {
        setReport({
          show: true,
          message: `Could not copy text: ${err.message}`,
          type: "danger",
        });
      }
    );
  };

  const handleQuestion = async (e) => {
    if (e) e.preventDefault();

    if (!question.trim()) {
      setReport({
        show: true,
        message: "Question cannot be empty.",
        type: "danger",
      });
      return;
    }

    setRunning(true);
    setTyping(true);

    const newMessage = {
      question: DOMPurify.sanitize(question),
      response: null,
      date: new Date(),
    };
    setMessages([...messages, newMessage]);

    try {
      const contextMessages = messages
        .slice(-contextQuestions)
        .map((msg) => msg.response)
        .filter(Boolean);

      const data = {
        instruction: DOMPurify.sanitize(instruction),
        question: DOMPurify.sanitize(question),
        contextMessages: contextMessages.map((msg) => DOMPurify.sanitize(msg)),
      };

      const result = await runData(data, "/compose/");

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1].response =
          DOMPurify.sanitize(result?.data?.message);
        return updatedMessages;
      });
      setQuestion("");
    } catch (error) {
      if (error.status === 402) {
        setRestricted(true);
      } else {
        setReport({
          show: true,
          message: "An error occurred while processing your request.",
          type: "danger",
        });
      }
    } finally {
      setRunning(false);
      setTyping(false);
    }
  };

  const handleRestartChat = () => {
    const success = localData("remove", "chatMessages");
    if (success) {
      setMessages([]);
      setReport({
        show: true,
        message: "Chat has been successfully restarted.",
        type: "success",
      });
    } else {
      setReport({
        show: true,
        message: "Failed to restart chat.",
        type: "danger",
      });
    }
  };

  const handleKeyDown = (event, action) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      action();
    }
  };

  const saveInstruction = () => {
    localData("save", "instruction", instruction);
    setShowInstructionModal(false);
  };

  const saveContextQuestions = () => {
    localData("save", "contextQuestions", contextQuestions);
    setShowContextModal(false);
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30vw",
    },
  };

  const renderMessage = (message, index) => {
    return (
      <React.Fragment key={index}>
        <div className="d-flex mb-3 justify-content-end">
          <div
            className="p-3 rounded bg-primary text-white"
            style={{ maxWidth: "75%", minWidth: "200px", position: "relative" }}
          >
            <div className="d-flex justify-content-between">
              <small className="mr-4" style={{ paddingRight: "20px" }}>
                <FontAwesomeIcon icon={faUserCircle} /> You
              </small>
              <small>
                {moment(message.date).format("Do of MMMM YYYY, h:mm")}
              </small>
            </div>
            <hr />
            <div
              dangerouslySetInnerHTML={{
                __html: marked.parse(
                  unescape(message.question || "Empty Message")
                ),
              }}
            ></div>
          </div>
        </div>
        {message.response && (
          <div className="d-flex mb-3 justify-content-start">
            <div
              className="p-3 rounded bg-light"
              style={{
                maxWidth: "75%",
                minWidth: "200px",
                position: "relative",
              }}
            >
              <div className="d-flex justify-content-between">
                <small className="mr-4" style={{ paddingRight: "20px" }}>
                  <FontAwesomeIcon icon={faRobot} /> Composer
                </small>
                <small>
                  {moment(message.date).format("Do of MMMM YYYY, h:mm")}
                </small>
              </div>
              <hr />
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parse(unescape(message.response)),
                }}
              ></div>
              <button
                onClick={() => copyToClipboard(unescape(message.response))}
                title="Copy"
                style={{
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                  padding: "0",
                }}
                aria-label="Copy to clipboard"
              >
                <FontAwesomeIcon icon={faClipboard} />
              </button>
              <button
                onClick={() => handleRegenerate(index)}
                title="Regenerate"
                style={{
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                  padding: "0",
                  marginLeft: "10px",
                }}
                aria-label="Regenerate response"
              >
                <FontAwesomeIcon icon={faRedo} />
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const handleRegenerate = async (index) => {
    setRunning(true);
    setTyping(true);

    const lastQuestion = messages[index].question;

    try {
      const data = {
        instruction: DOMPurify.sanitize(instruction),
        question: DOMPurify.sanitize(lastQuestion),
        contextMessages: messages.map((msg) =>
          DOMPurify.sanitize(msg.response)
        ),
      };

      const result = await runData(data, "/compose/");
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[index].response = DOMPurify.sanitize(
          result?.data?.message
        );
        return updatedMessages;
      });
    } catch (error) {
      if (error.status === 402) {
        setRestricted(true);
      } else {
        setReport({
          show: true,
          message: "An error occurred while processing your request.",
          type: "danger",
        });
      }
    } finally {
      setRunning(false);
      setTyping(false);
    }
  };

  if (loading) {
    return <Processing />;
  }

  if (restricted) {
    return <Restricted product="" />;
  }

  return (
    <Container>
      <Helmet>
        <title>Composer - Care Quality Support</title>
      </Helmet>
      <Row className="align-items-center mb-4">
        <Col className="mt-4">
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard/">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Composer</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col className="text-end">
          <Dropdown as={ButtonGroup} style={{ marginRight: "8px" }}>
            <Button variant="secondary">Options</Button>
            <Dropdown.Toggle
              split
              variant="secondary"
              id="dropdown-split-basic"
            />
            <Dropdown.Menu>
              <Dropdown.Item
                className="text-decoration-none text-body dropdown-item"
                onClick={handleRestartChat}
              >
                Start New Chat
              </Dropdown.Item>
              <Dropdown.Item
                className="text-decoration-none text-body dropdown-item"
                onClick={() => setShowInstructionModal(true)}
              >
                Customise Chat
              </Dropdown.Item>
              <Dropdown.Item
                className="text-decoration-none text-body dropdown-item"
                onClick={() => setShowContextModal(true)}
              >
                Context Messages
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="mt-3">
                  {/* Sidebar Navigation */}
                  <Col md={3}>
            <Card className="sidebar">
              <Card.Header as="h5">Organisation Navigation</Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item as={Link} to="/organisation/overview">
                  Overview
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/team">
                  Our Team
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/composer" active>
                  Composer
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/billing">
                  Billing
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/shortcodes/">
                  Shortcodes
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/subscriptions/">
                  Subscriptions
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col md={9}>
        <div className="mb-4 border-0" style={{ height: "75vh" }}>
          <div
            style={{ overflowY: "auto", height: "100%", padding: "20px" }}
          >
            <ul className="list-unstyled" style={{ minHeight: "80vh" }}>
              {messages.length > 0 ? (
                messages.map((message, index) => renderMessage(message, index))
              ) : (
                <li className="d-flex justify-content-center align-items-center vh-50">
                  <h4>Start a conversation</h4>
                </li>
              )}
              <div ref={endOfList}></div>
            </ul>
          </div>
        </div><div
        style={{
          position: "sticky",
          bottom: 0,
          zIndex: 1,
          background: "white",
        }}
      >
        {typing && (
          <li className="d-flex justify-content-start">
            <div className="p-3 rounded bg-light mb-4">
              <small>
                <FontAwesomeIcon icon={faCommentDots} /> Composer is typing...
              </small>
            </div>
          </li>
        )}
        <div className="d-flex p-3">
          <textarea
            value={question}
            onChange={(event) => setQuestion(event.target.value)}
            placeholder="Type your message here..."
            className="form-control me-2"
            aria-label="Question input"
            style={{ resize: "none" }}
            disabled={running}
          />
          <div className="d-flex me-2">
            <button className="btn btn-secondary me-2" title="Attach file">
              <FontAwesomeIcon icon={faPaperclip} />
            </button>
            <button className="btn btn-secondary me-2" title="Attach image">
              <FontAwesomeIcon icon={faImage} />
            </button>
            <button className="btn btn-secondary me-2" title="Attach document">
              <FontAwesomeIcon icon={faFile} />
            </button>
          </div>
          <button
            onClick={handleQuestion}
            onKeyDown={(event) => handleKeyDown(event, handleQuestion)}
            className="btn btn-primary"
            disabled={running}
          >
            SEND
          </button>
        </div>
      </div>
      </Col>
      </Row>
      
      <Modal
        isOpen={showInstructionModal}
        onRequestClose={() => setShowInstructionModal(false)}
        style={customStyles}
      >
        <div className="text-center bg-light p-4 rounded">
          <h4>Set Instructions</h4>
          <hr />
          <Form>
            <Form.Group controlId="formInstruction">
              <Form.Label>Instruction</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={instruction}
                onChange={(e) => setInstruction(e.target.value)}
              />
            </Form.Group>
            <br />
            <Button
              variant="primary"
              className="w-100 rounded-pill"
              onClick={saveInstruction}
            >
              Save
            </Button>
          </Form>
        </div>
      </Modal>

      <Modal
        isOpen={showContextModal}
        onRequestClose={() => setShowContextModal(false)}
        style={customStyles}
      >
        <div className="text-center bg-light p-4 rounded">
          <h4>Set Context Messages</h4>
          <hr />
          <Form>
            <Form.Group controlId="formContextQuestions">
              <Form.Label>Number of Context Messages</Form.Label>
              <Form.Control
                type="number"
                value={contextQuestions}
                onChange={(e) => setContextQuestions(e.target.value)}
                min="1"
              />
            </Form.Group>
            <br />
            <Button
              variant="primary"
              className="w-100 rounded-pill"
              onClick={saveContextQuestions}
            >
              Save
            </Button>
          </Form>
        </div>
      </Modal>
    </Container>
  );
};

export default ChatPage;
