import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import useSessionWatch from '../hooks/useSessionWatch';

/**
 * PublicRoute component that checks if the session is valid before rendering the public component.
 * If the session is valid, it redirects to the appropriate page based on the redirect parameter.
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.element - The public component to render.
 * @returns {JSX.Element} - The public component or a redirect to the dashboard page.
 */
const PublicRoute = ({ element }) => {
  const sessionValid = useSessionWatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get('redirect');

  return sessionValid ? (
    <Navigate to={redirectUrl || '/'} />
  ) : (
    element
  );
};

PublicRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default PublicRoute;
