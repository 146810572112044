import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Card,
  Button,
  ListGroup,
  Modal,
  Form,
  Table,
  Pagination,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faFileSignature, faEdit } from "@fortawesome/free-solid-svg-icons";

// Sample data for services and packages
const servicesData = [
  { reference: "SERV001", title: "Service 1" },
  { reference: "SERV002", title: "Service 2" },
  { reference: "SERV003", title: "Service 3" },
];

const packagesData = [
  { reference: "PACK001", title: "Package 1" },
  { reference: "PACK002", title: "Package 2" },
  { reference: "PACK003", title: "Package 3" },
];

// Dummy data for organization example
const organizationData = {
  name: "XYZ Corporation",
  description: "Providing quality business services since 1990.",
  profilesCount: 5,
  usersCount: 12,
  subscriptions: [
    { reference: "SUB001", name: "Premium Plan", type: "Service", startDate: "2022-01-01", endDate: "2023-01-01", status: "Active" },
    { reference: "SUB002", name: "Basic Plan", type: "Policy", startDate: "2021-06-15", endDate: "2022-06-15", status: "Expired" },
  ],
};

const OrganisationOverview = () => {
  const [organization, setOrganization] = useState(organizationData);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [filterTerm, setFilterTerm] = useState(""); // Filter term for company profile or reference
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 12; // Limit to 12 items per page

  // State for managing new subscription inputs
  const [newSubscription, setNewSubscription] = useState({
    type: "service",
    plan: "",
    levelOfAccess: "full-package",
  });

  // State for company details edit modal
  const [formData, setFormData] = useState({
    name: organization.name,
    description: organization.description,
  });

  // Filtering function
  const filteredSubscriptions = organization.subscriptions.filter((sub) =>
    sub.reference.toLowerCase().includes(filterTerm.toLowerCase()) ||
    sub.name.toLowerCase().includes(filterTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSubscriptions = filteredSubscriptions.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredSubscriptions.length / itemsPerPage);

  const handleFilterChange = (e) => {
    setFilterTerm(e.target.value);
    setCurrentPage(1); // Reset to page 1 on filter change
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubscriptionChange = (e) => {
    const { name, value } = e.target;
    setNewSubscription((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to generate automatic subscription dates (start and end)
  const generateSubscriptionDates = () => {
    const today = new Date();
    const startDate = today.toISOString().split("T")[0];
    const endDate = new Date(today.setFullYear(today.getFullYear() + 1))
      .toISOString()
      .split("T")[0];
    return { startDate, endDate };
  };

  const handleAddSubscription = () => {
    const { startDate, endDate } = generateSubscriptionDates();
    const newSub = {
      reference: `SUB00${organization.subscriptions.length + 1}`,
      name: newSubscription.plan,
      type: newSubscription.type === "service" ? "Service" : "Policy",
      startDate,
      endDate,
      status: "Active",
    };

    if (newSubscription.type === "policies") {
      newSub.levelOfAccess = newSubscription.levelOfAccess;
    }

    setOrganization({
      ...organization,
      subscriptions: [...organization.subscriptions, newSub],
    });
    setShowSubscriptionModal(false);
  };

  const handleSaveChanges = () => {
    setOrganization({
      ...organization,
      name: formData.name,
      description: formData.description,
    });
    setShowEditModal(false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Organisation - Care Quality Support</title>
      </Helmet>

      <Container className="mb-4">
        {/* Breadcrumb navigation */}
        <Row className="align-items-center mb-4">
          <Col className="mt-4">
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Organisation</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row>
          {/* Sidebar Navigation */}
          <Col md={3}>
            <Card className="sidebar">
              <Card.Header as="h5">Organisation Navigation</Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item as={Link} to="/organisation/overview" active>
                  Overview
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/team">
                  Our Team
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/composer">
                  Composer
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/billing">
                  Billing
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/shortcodes/">
                  Shortcodes
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/subscriptions/">
                  Subscriptions
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

          {/* Main Content */}
          <Col md={9}>
            {/* Organization Details with Edit Button */}
            <Card className="mb-4">
              <Card.Header
                as="h5"
                className="d-flex justify-content-between align-items-center"
              >
                Organization Details
                <Button variant="outline-primary" onClick={() => setShowEditModal(true)}>
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </Button>
              </Card.Header>
              <Card.Body>
                <Card.Title>{organization.name}</Card.Title>
                <Card.Text>{organization.description}</Card.Text>
              </Card.Body>
            </Card>

            {/* Profiles, Users, and Composer Overview */}
            <Row>
              <Col md={4}>
                <Card className="text-center mb-4">
                  <Card.Body>
                    <FontAwesomeIcon icon={faUsers} size="3x" className="mb-3" />
                    <Card.Title>{organization.profilesCount} Profiles</Card.Title>
                    <Card.Text>Manage business profiles in your organization.</Card.Text>
                    <Button variant="primary" as={Link} to="/organisation/profiles/">
                      View Profiles
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className="text-center mb-4">
                  <Card.Body>
                    <FontAwesomeIcon icon={faUsers} size="3x" className="mb-3" />
                    <Card.Title>{organization.usersCount} Users</Card.Title>
                    <Card.Text>View and manage organization users.</Card.Text>
                    <Button variant="primary" as={Link} to="/organisation/team/">
                      View Users
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className="text-center mb-4">
                  <Card.Body>
                    <FontAwesomeIcon icon={faFileSignature} size="3x" className="mb-3" />
                    <Card.Title>Composer</Card.Title>
                    <Card.Text>Create and edit organization resources.</Card.Text>
                    <Button variant="primary" as={Link} to="/organisation/composer/">
                      Open Composer
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Subscriptions Overview with Filters */}
            <Card className="mb-4">
              <Card.Header as="h5" className="d-flex justify-content-between align-items-center">
                Subscriptions
                <Button variant="outline-primary" onClick={() => setShowSubscriptionModal(true)}>
                  Add Subscription
                </Button>
              </Card.Header>
              <Card.Body>
                {/* Filter Input */}
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Filter by Reference or Name"
                    value={filterTerm}
                    onChange={handleFilterChange}
                  />
                </Form.Group>

                {/* Subscriptions Table */}
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Reference</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentSubscriptions.map((sub, index) => (
                      <tr key={sub.reference}>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>{sub.reference}</td>
                        <td>{sub.name}</td>
                        <td>{sub.type}</td>
                        <td>{new Date(sub.startDate).toLocaleDateString()}</td>
                        <td>{new Date(sub.endDate).toLocaleDateString()}</td>
                        <td>{sub.status}</td>
                        <td>
                          <Button variant="outline-primary" as={Link} to={`/organisation/subscriptions/${sub.reference}`}>
                            View Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {/* Pagination */}
                {filteredSubscriptions.length > itemsPerPage && (
                  <Pagination className="justify-content-center">
                    {[...Array(totalPages)].map((_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                  </Pagination>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Edit Organization Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Organization Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveChanges}>
                Save Changes
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Add Subscription Modal */}
      <Modal show={showSubscriptionModal} onHide={() => setShowSubscriptionModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Subscription Type</Form.Label>
              <Form.Select
                name="type"
                value={newSubscription.type}
                onChange={handleSubscriptionChange}
              >
                <option value="service">Service</option>
                <option value="policies">Policies</option>
              </Form.Select>
            </Form.Group>

            {newSubscription.type === "service" ? (
              <Form.Group className="mb-3">
                <Form.Label>Select Service</Form.Label>
                <Form.Select
                  name="plan"
                  value={newSubscription.plan}
                  onChange={handleSubscriptionChange}
                  required
                >
                  <option value="">Select a service...</option>
                  {servicesData.map((service) => (
                    <option key={service.reference} value={service.title}>
                      {service.title}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Select Package</Form.Label>
                  <Form.Select
                    name="plan"
                    value={newSubscription.plan}
                    onChange={handleSubscriptionChange}
                    required
                  >
                    <option value="">Select a package...</option>
                    {packagesData.map((pkg) => (
                      <option key={pkg.reference} value={pkg.title}>
                        {pkg.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Level of Access</Form.Label>
                  <Form.Select
                    name="levelOfAccess"
                    value={newSubscription.levelOfAccess}
                    onChange={handleSubscriptionChange}
                    required
                  >
                    <option value="full-package">Full Package</option>
                    <option value="single-category">Single Category</option>
                    <option value="single-policy">Single Policy</option>
                  </Form.Select>
                </Form.Group>
              </>
            )}

            <div className="d-flex justify-content-between">
              <Button variant="secondary" onClick={() => setShowSubscriptionModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleAddSubscription}>
                Add Subscription
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default OrganisationOverview;
