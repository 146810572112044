import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  Pagination,
  FormControl,
  InputGroup,
  Card,
} from "react-bootstrap";

const UserActivities = ({ activities }) => {
  // State variables
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesPerPage] = useState(10); // Set the number of activities per page
  const [filteredActivities, setFilteredActivities] = useState(activities); // Filtered list of activities
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering activities

  // Update filtered activities when search term or activities change
  useEffect(() => {
    setFilteredActivities(
      activities.filter((activity) =>
        activity.description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setCurrentPage(1); // Reset to page 1 on new filter
  }, [searchTerm, activities]);

  // Pagination logic
  const indexOfLastActivity = currentPage * activitiesPerPage;
  const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
  const currentActivities = filteredActivities.slice(
    indexOfFirstActivity,
    indexOfLastActivity
  );
  const totalPages = Math.ceil(filteredActivities.length / activitiesPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Card className="p-4" id="activities">
      {/* Search Filter Input */}
      <InputGroup className="mb-3">
        <InputGroup.Text>Filter Activities</InputGroup.Text>
        <FormControl
          type="text"
          placeholder="Search activities"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>

      {/* Activities Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Activity</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {currentActivities.map((activity, index) => (
            <tr key={activity.id}>
              <td>{indexOfFirstActivity + index + 1}</td>
              <td>{activity.description}</td>
              <td>{new Date(activity.date).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination - Hide if total activities are less than the activities per page */}
      {filteredActivities.length > activitiesPerPage && (
        <Pagination className="justify-content-center">
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </Card>
  );
};

// PropTypes for validation
UserActivities.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default UserActivities;
