// src/components/WarningModal.js

import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

/**
 * WarningModal component that displays a warning modal when the user is inactive.
 * It logs out the user if they do not respond within a countdown period.
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.show - Determines if the modal should be shown.
 * @param {function} props.onReset - Function to reset the inactivity countdown.
 * @param {function} props.onLogout - Function to log out the user.
 * @returns {JSX.Element} - The warning modal component.
 */
const WarningModal = ({ show, onReset, onLogout }) => {
  const [countdown, setCountdown] = useState(120);

  useEffect(() => {
    if (show) {
      const intervalId = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(intervalId);
            onLogout();
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [show, onLogout]);

  /**
   * Resets the countdown and hides the modal.
   */
  const handleStayLoggedIn = () => {
    setCountdown(120);
    onReset();
  };

  return (
    <React.Fragment>
      {show && (
        <div className="modal-overlay">
          <Helmet>
            <title>Inactive :: Logging Out - Care Quality Support</title>
          </Helmet>
        </div>
      )}
      <Modal
        show={show}
        onHide={handleStayLoggedIn}
        backdrop="static"
        className="out-modal"
      >
        <Modal.Header>
          <Modal.Title>Session Expiration Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have been inactive for a while. You will be logged out in{" "}
          {countdown} seconds. Please click "Stay Logged In" to remain logged
          in.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="modal-footer-button"
            onClick={handleStayLoggedIn}
          >
            Stay Logged In
          </Button>
          <Button
            variant="danger"
            className="modal-footer-button"
            onClick={onLogout}
          >
            Log Out
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default WarningModal;
