// src/components/Header.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes for validation
import { Nav, Spinner, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import LazyImage from "./LazyImage";
import logo from "../assets/logo.png";
import userImage from "../assets/user-avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Person, ShieldCheck, List, Bell } from "react-bootstrap-icons";
import { localData } from "../context/processor";

const Header = ({ navClosed, toggleNav }) => {
  const [isReady, setIsReady] = useState(false);
  const { authenticated, logout } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 1000);
  }, [authenticated]);

  const handleLogout = () => logout();

  if (!isReady) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" size="lg" />
      </div>
    );
  }

  return (
    <header className="bg-light">
      <div className={`logosec`}>
        <Link to={`/`}>
          <LazyImage
            src={logo}
            alt="Logo"
            className="ml-2 mr-2"
            style={{
              width: navClosed ? "60px" : "130px",
            }}
          />
        </Link>
        <List
          size={40}
          className={`menuicn ${navClosed ? "menuicn-center" : ""}`}
          onClick={toggleNav} // Adding the click handler to toggle navigation
        />
      </div>

      <div className="searchbar">
        <input type="text" placeholder="Search" />
        <div className="searchbtn">
          <FontAwesomeIcon
            icon={faSearch}
            color="white"
            className="icn srchicn"
          ></FontAwesomeIcon>
        </div>
      </div>

      <div className="message">
        <div className="circle"></div>
        <Bell size={30} />
        <Dropdown as={Nav.Item} align="end" className="ml-2">
          <Dropdown.Toggle
            as={Nav.Link}
            className="pt-1 px-0 d-flex align-items-center"
          >
            <div className="media d-flex align-items-center">
              <LazyImage
                src={localData("get", "session").avatar || userImage}
                className="user-avatar md-avatar rounded-circle"
                style={{ height: "35px", width: "35px" }}
              />
              <div
                className={`media-body ms-2 text-dark align-items-center d-flex ${
                  navClosed ? "hide-name" : ""
                }`}
              >
                <span className="mb-0 font-small fw-bold me-2 user-name-toggle d-none d-md-block">
                  {`${localData("get", "session").name || "You"}`}
                </span>
                <i className="bi bi-caret-down-fill"></i>
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="user-dropdown dropdown-menu-end mt-2">
            <Link to={`/profile/`} className="dropdown-item fw-bold">
              <Person className="me-2" /> Profile
            </Link>
            <Link to={`/support/`} className="dropdown-item fw-bold">
              <ShieldCheck className="me-2" /> Support
            </Link>

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold" onClick={handleLogout}>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="text-danger me-2"
              />
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
};

// Add PropTypes validation for the 'navClosed' and 'toggleNav' props
Header.propTypes = {
  navClosed: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

export default Header;
