// ErrorPage.js

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";

/**
 * ErrorPage component renders a styled error message.
 *
 * @param {Object} props - React props object
 * @param {string} props.error - Error message to display
 * @returns {JSX.Element} The rendered component
 */
function ErrorPage({ error }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>Error - Care Quality Support</title>
      </Helmet>
      <Container>
        <Row className="d-flex justify-content-center align-items-center vh-100">
          <Col className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              size="5x"
              className="text-warning"
            />
            <h4>{error}</h4>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ErrorPage;
