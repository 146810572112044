// src/components/Navigation.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes for validation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faDashboard,
  faFolder,
  faLifeRing,
  faMagnifyingGlass,
  faPeopleGroup,
  faQuestionCircle,
  faRobot,
  faSignOutAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { logout } from "../utils/auth";
import { Button } from "react-bootstrap";

const Navigation = ({ navClosed, toggleNav }) => {
  const handleLogout = () => logout();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getHeadingText = (title) => {
    if (isMobile) {
      return title;
    }
    return navClosed ? "" : title;
  };
  return (
    <div
      className={`bg-light navcontainer ${navClosed ? "navclose" : ""}`}
      style={{ boxShadow: "10px 0 15px -5px rgba(0, 0, 0, 0.3)" }}
    >
      <nav className="nav">
        {/* Navigation Options */}
        <div className="nav-options mt-2">
          {/* Dashboard Section */}
          {[
            { to: "/", label: "Dashboard", icon: faDashboard },
            { to: "/directories/", label: "Directories", icon: faFolder },
            { to: "/composer/", label: "Composer", icon: faRobot },
          ].map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              className={`nav-option option ${navClosed ? "hidden" : ""}`}
              onClick={isMobile ? toggleNav : null}
            >
              <FontAwesomeIcon icon={link.icon} className="nav-img" />
              <h3>{link.label}</h3>
            </NavLink>
          ))}

          {/* Manage Accounts Section */}
          <h6 className="mt-5">{getHeadingText("Manage Accounts")}</h6>
          {[
            { to: "/team/", label: "Our Team", icon: faPeopleGroup },
            { to: "/clients/", label: "Our Clients", icon: faUsers },
            { to: "/settings/", label: "Settings", icon: faCogs },
          ].map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              className={`nav-option option ${navClosed ? "hidden" : ""}`}
              onClick={isMobile ? toggleNav : null}
            >
              <FontAwesomeIcon icon={link.icon} className="nav-img" />
              <h3>{link.label}</h3>
            </NavLink>
          ))}

          {/* Getting Help Section */}
          <h6 className="mt-5">{getHeadingText("Getting Help")}</h6>
          {[
            { to: "/search/", label: "Search", icon: faMagnifyingGlass },
            { to: "/support/", label: "Support", icon: faLifeRing },
          ].map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              className={`nav-option option ${navClosed ? "hidden" : ""}`}
              onClick={isMobile ? toggleNav : null}
            >
              <FontAwesomeIcon icon={link.icon} className="nav-img" />
              <h3>{link.label}</h3>
            </NavLink>
          ))}
        </div>
        {/* Sticky Logout Button */}
        <Button
          className={`nav-option option logout-button mt-4 py-4`}
          style={{ borderRadius: "0" }}
        >
          <FontAwesomeIcon icon={faQuestionCircle} className="nav-img" />
          <h3 className={`${navClosed ? "hidden" : ""}`}>System Guide</h3>
        </Button>
      </nav>
    </div>
  );
};

// Add PropTypes validation for the 'navClosed' and 'toggleNav' props
Navigation.propTypes = {
  navClosed: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func,
};

export default Navigation;
