// File: UserEventsPage.js

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb, Card, ListGroup } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import UserHistory from "./UserHistoryItems";  // Import the UserHistory component
import ErrorPage from "../includes/ErrorPage";
import Processing from "../includes/Processing";
import { runData } from "../context/processor";
import DOMPurify from "dompurify";

const UserEventsPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]); // State for user events history
  const params = useParams();

  useEffect(() => {
    const fetchUserEvents = async () => {
      try {
        const data = {};
        const response = await runData(data, `/team/${params.user}/`);
        if (response.data) {
          setHistory(response.data.history);
        } else if (response.error) {
          setError(DOMPurify.sanitize(response.error.message));
        } else {
          setError("Unknown error");
        }
      } catch (err) {
        setError(DOMPurify.sanitize(err.message));
      } finally {
        setLoading(false);
      }
    };

    fetchUserEvents();
  }, [params.user]);

  if (loading) {
    return <Processing />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>User Events - Care Quality Support</title>
      </Helmet>
      <Container className="mb-4">
        <Row className="align-items-center mb-4">
          <Col className="mt-4">
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item href="/organisation/team/">Team Members</Breadcrumb.Item>
              <Breadcrumb.Item active>Events</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          {/* Sidebar */}
          <Col md={3}>
            <Card>
              <Card.Header as="h5">User Navigation</Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item as={Link} to={`/organisation/team/${params.user}`}>
                  Overview
                </ListGroup.Item>
                <ListGroup.Item as={Link} to={`/organisation/team/${params.user}/activities`}>
                  Activities
                </ListGroup.Item>
                <ListGroup.Item active>
                  Events
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

          {/* Main Content - User Events */}
          <Col md={9}>
            <UserHistory history={history} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default UserEventsPage;
