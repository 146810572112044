// File path: /src/utils/auth.js

import { localData } from "../context/processor";

let authChangeCallback = null;

/**
 * Checks if the user is authenticated by verifying the session and expiry data.
 * @returns {boolean} - True if authenticated, otherwise false.
 */
export const isAuthenticated = () => {
  const session = localData("get", "session");
  const expiry = localData("get", "expiry");

  if (!session || !expiry) return false;

  const currentTime = new Date().getTime();
  const expiryTime = Number(expiry);

  if (isNaN(expiryTime)) return false;

  return currentTime < expiryTime;
};

/**
 * Sets the session data with a specified timeout and slot.
 * @param {object} sessionData - The session data to be stored.
 * @param {number} timeoutMinutes - The session timeout duration in minutes.
 * @param {string} slot - The slot to be saved with the session.
 */
export const setSession = (sessionData, timeoutMinutes, slot) => {
  const currentTime = new Date().getTime();
  const expiryTime = currentTime + timeoutMinutes * 60 * 1000;
  localData("save", "session", sessionData);
  localData("save", "slot", slot);
  localData("save", "expiry", expiryTime);
  if (authChangeCallback) authChangeCallback(true);
};

/**
 * Registers a callback to be invoked when authentication status changes.
 * @param {function} callback - The callback function to be invoked.
 * @returns {function} - A function to unregister the callback.
 */
export const onAuthChange = (callback) => {
  authChangeCallback = callback;
  return () => {
    authChangeCallback = null;
  };
};

/**
 * Logs the user out by removing session data and redirecting to the login page.
 */
export const logout = () => {
  if (authChangeCallback) authChangeCallback(false);
  localData("remove", "session");
  localData("remove", "expiry");
  window.location.href = "/login";
};
