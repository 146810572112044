import React, { useState } from "react";
import { Container, Row, Col, Breadcrumb, Card, Table, Button, Modal, Form, InputGroup, FormControl, ListGroup, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Sample shortcodes data
const shortcodesData = [
  { id: 1, shortcode: "{{company}}", description: "Company Name" },
  { id: 2, shortcode: "{{address}}", description: "Company Address" },
  { id: 3, shortcode: "{{email}}", description: "Contact Email" },
  // Add more data for testing...
];

const Shortcodes = () => {
  const [shortcodes, setShortcodes] = useState(shortcodesData); // Initial shortcodes data
  const [searchTerm, setSearchTerm] = useState(""); // State for search/filter input
  const [showModal, setShowModal] = useState(false); // Modal visibility state for creating/editing shortcodes
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal visibility state for delete confirmation
  const [isEdit, setIsEdit] = useState(false); // To track if it's an edit operation
  const [selectedShortcode, setSelectedShortcode] = useState(null); // Selected shortcode for editing or deleting

  const [shortcodeFormData, setShortcodeFormData] = useState({
    shortcode: "",
    description: "",
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShortcodeFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Open the modal for creating or editing
  const handleOpenModal = (shortcode = null) => {
    if (shortcode) {
      setShortcodeFormData(shortcode);
      setSelectedShortcode(shortcode);
      setIsEdit(true);
    } else {
      setShortcodeFormData({ shortcode: "", description: "" });
      setIsEdit(false);
    }
    setShowModal(true);
  };

  // Open delete confirmation modal
  const handleOpenDeleteModal = (shortcode) => {
    setSelectedShortcode(shortcode);
    setShowDeleteModal(true);
  };

  // Save the shortcode (create or update)
  const handleSaveShortcode = () => {
    if (isEdit) {
      setShortcodes((prevShortcodes) =>
        prevShortcodes.map((sc) =>
          sc.id === selectedShortcode.id ? { ...selectedShortcode, ...shortcodeFormData } : sc
        )
      );
    } else {
      setShortcodes((prevShortcodes) => [
        ...prevShortcodes,
        { id: shortcodes.length + 1, ...shortcodeFormData },
      ]);
    }
    setShowModal(false);
  };

  // Handle shortcode deletion
  const handleDeleteShortcode = () => {
    setShortcodes((prevShortcodes) => prevShortcodes.filter((sc) => sc.id !== selectedShortcode.id));
    setShowDeleteModal(false);
  };

  // Filtered shortcodes based on search input
  const filteredShortcodes = shortcodes.filter(
    (shortcode) =>
      shortcode.shortcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      shortcode.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentShortcodes = filteredShortcodes.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredShortcodes.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Shortcodes - Care Quality Support</title>
      </Helmet>

      <Container className="mb-4">
        {/* Breadcrumb navigation */}
        <Row className="align-items-center mb-4">
          <Col className="mt-4">
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Shortcodes</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row>
          {/* Sidebar Navigation */}
          <Col md={3}>
            <Card className="sidebar">
              <Card.Header as="h5">Organisation Navigation</Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item as={Link} to="/organisation/overview">
                  Overview
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/team">
                  Our Team
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/composer">
                  Composer
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/billing">
                  Billing
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/organisation/shortcodes/" active>
                  Shortcodes
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

          {/* Main Content */}
          <Col md={9}>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h5>Shortcodes</h5>
                <Button variant="outline-primary" onClick={() => handleOpenModal()}>
                  Create Shortcode
                </Button>
              </Card.Header>
              <Card.Body>
                {/* Filter/Search Input */}
                <InputGroup className="mb-3">
                  <InputGroup.Text>Filter Shortcodes</InputGroup.Text>
                  <FormControl
                    type="text"
                    placeholder="Search shortcodes or description..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>

                {/* Shortcodes Table */}
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Shortcode</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentShortcodes.map((shortcode) => (
                      <tr key={shortcode.id}>
                        <td>{shortcode.id}</td>
                        <td>{shortcode.shortcode}</td>
                        <td>{shortcode.description}</td>
                        <td>
                          <Button
                            variant="outline-secondary"
                            className="me-2"
                            onClick={() => handleOpenModal(shortcode)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outline-danger"
                            onClick={() => handleOpenDeleteModal(shortcode)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {/* Pagination */}
                {filteredShortcodes.length > itemsPerPage && (
                  <Pagination className="justify-content-center">
                    <Pagination.First
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    />
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                    <Pagination.Last
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Shortcode Modal for Creating/Editing */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Shortcode" : "Create Shortcode"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Shortcode</Form.Label>
              <Form.Control
                type="text"
                name="shortcode"
                value={shortcodeFormData.shortcode}
                onChange={handleInputChange}
                placeholder="Enter shortcode, e.g., {{company}}"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={shortcodeFormData.description}
                onChange={handleInputChange}
                rows={3}
                placeholder="Enter description for the shortcode"
                required
              />
            </Form.Group>

            <div className="d-flex justify-content-between">
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveShortcode}>
                {isEdit ? "Update Shortcode" : "Create Shortcode"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the shortcode: <strong>{selectedShortcode?.shortcode}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteShortcode}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Shortcodes;
