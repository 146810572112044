// src/hooks/useSlot.js

import { useState, useEffect } from "react";
import { localData } from "../context/processor";

/**
 * Validate if the slot value is within the allowed range (1-10).
 * @param {number} slot - The slot value to validate.
 * @returns {boolean} - True if valid, otherwise false.
 */
const isValidSlot = (slot) => {
  if (slot >= 1 && slot <= 10) {
    return true;
  } else {
    console.warn(
      `Invalid slot value: ${slot}. Slot should be between 1 and 10.`
    );
    return false;
  }
};

/**
 * Custom hook to listen for changes to the 'slot' key in localStorage.
 * @returns {number} - The current value of the 'slot' key in localStorage.
 */
function useSlot() {
  const [slot, setSlot] = useState(() => {
    // Initialize state with the current 'slot' value from localStorage
    const savedSlot = localData("get", "slot");
    const initialSlot = savedSlot !== null ? parseInt(savedSlot, 10) : 0;
    return isValidSlot(initialSlot) ? initialSlot : 0;
  });

  useEffect(() => {
    /**
     * Event handler for the 'storage' event.
     * @param {StorageEvent} event - The storage event.
     */
    const handleStorageChange = (event) => {
      if (event.key === "slot") {
        const newSlot =
          event.newValue !== null ? parseInt(event.newValue, 10) : 0;
        setSlot(isValidSlot(newSlot) ? newSlot : 0);
      }
    };

    // Add event listener for the 'storage' event
    window.addEventListener("storage", handleStorageChange);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return slot;
}

export default useSlot;
