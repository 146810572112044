// File: UserActivitiesPage.js

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb, Card, ListGroup } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import UserActivities from "./UserActivitiesItems";  // Import the UserActivities component
import ErrorPage from "../includes/ErrorPage";
import Processing from "../includes/Processing";
import { runData } from "../context/processor";
import DOMPurify from "dompurify";

const UserActivitiesPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activities, setActivities] = useState([]); // State for user activities
  const params = useParams();

  useEffect(() => {
    const fetchUserActivities = async () => {
      try {
        const data = {};
        const response = await runData(data, `/team/${params.user}/`);
        if (response.data) {
          setActivities(response.data.activities);
        } else if (response.error) {
          setError(DOMPurify.sanitize(response.error.message));
        } else {
          setError("Unknown error");
        }
      } catch (err) {
        setError(DOMPurify.sanitize(err.message));
      } finally {
        setLoading(false);
      }
    };

    fetchUserActivities();
  }, [params.user]);

  if (loading) {
    return <Processing />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>User Activities - Care Quality Support</title>
      </Helmet>
      <Container className="mb-4">
        <Row className="align-items-center mb-4">
          <Col className="mt-4">
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item href="/organisation/team/">Team Members</Breadcrumb.Item>
              <Breadcrumb.Item active>Activities</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          {/* Sidebar */}
          <Col md={3}>
            <Card>
              <Card.Header as="h5">User Navigation</Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item as={Link} to={`/organisation/team/${params.user}`} >
                  Overview
                </ListGroup.Item>
                <ListGroup.Item active>
                  Activities
                </ListGroup.Item>
                <ListGroup.Item as={Link} to={`/organisation/team/${params.user}/events`}>
                  Events
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

          {/* Main Content - User Activities */}
          <Col md={9}>
            <UserActivities activities={activities} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default UserActivitiesPage;
