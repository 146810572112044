import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Modal,
  Form,
  Container,
} from "react-bootstrap";
import { Link, useParams, useNavigate, NavLink } from "react-router-dom";
import {
  FaAngleLeft,
  FaPlus,
  FaFolder,
  FaFileAlt,
  FaDownload,
  FaShare,
  FaEdit,
  FaTrash,
  FaFolderOpen,
  FaAngleRight,
} from "react-icons/fa";
//import useSlot from "../hooks/useSlot";
import DirectoryCreator from "./DirectoryCreator";

const DirectoryFolders = ({
  directories,
  updateDirectories,
  updateDocuments,
  parent,
  title,
  documents,
}) => {
  // State for items per page dropdown
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const itemsPerPageOptions = [6, 12, 24, 48];
  const params = useParams();
  const navigate = useNavigate();
  const detailsRef = useRef(null); // Reference for details section
  const directoryPath = `${params.directory ? params.directory : "directory"}`;

  // Separate states for pagination of directories and documents
  const [currentPage, setCurrentPage] = useState(1);
  //const slot = useSlot(); // Get user's slot information
  // State for modals
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddPackageModal, setShowAddPackageModal] = useState({
    show: false,
    isDocument: false,
  });
  const [selectedDirectory, setSelectedDirectory] = useState(null);
  const [newDirectoryName, setNewDirectoryName] = useState("");
  // State for filter type
  const [filterType, setFilterType] = useState("All");
  // State for selected item for details view
  const [selectedItem, setSelectedItem] = useState(null);
  // State for showing either details or activity
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    // Reset to the first page whenever the filter type changes
    setCurrentPage(1);
  }, [filterType]);

  // Reset selected item when URL changes (to prevent showing outdated data)
  useEffect(() => {
    setSelectedItem(null);
  }, [params]);

  // Scroll to the details section when an item is clicked
  useEffect(() => {
    if (selectedItem && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedItem]);

  // Handler for Rename Directory
  const handleRename = (directory) => {
    setSelectedDirectory(directory);
    setNewDirectoryName(directory.title);
    setShowRenameModal(true);
  };

  const handleRenameConfirm = () => {
    // Simulate API call and update directory name upon success
    const updatedDirectory = { ...selectedDirectory, title: newDirectoryName };
    if (directories.includes(selectedDirectory)) {
      updateDirectories((prevDirectories) =>
        prevDirectories.map((dir) =>
          dir.reference === selectedDirectory.reference ? updatedDirectory : dir
        )
      );
    } else {
      updateDocuments((prevDocuments) =>
        prevDocuments.map((doc) =>
          doc.reference === selectedDirectory.reference ? updatedDirectory : doc
        )
      );
    }
    setShowRenameModal(false);
  };

  // Handler for Delete Directory
  const handleDelete = (directory) => {
    setSelectedDirectory(directory);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    // Simulate API call and update state upon success
    if (directories.includes(selectedDirectory)) {
      updateDirectories((prevDirectories) =>
        prevDirectories.filter(
          (dir) => dir.reference !== selectedDirectory.reference
        )
      );
    } else {
      updateDocuments((prevDocuments) =>
        prevDocuments.filter(
          (doc) => doc.reference !== selectedDirectory.reference
        )
      );
    }
    setShowDeleteModal(false);
  };

  // Handler for adding a new directory or document
  const handleAdd = (isDocument) => {
    setShowAddPackageModal({ show: true, isDocument });
  };

  const handleAddPackageSuccess = (newItem) => {
    if (showAddPackageModal.isDocument) {
      updateDocuments((prevDocuments) => [...prevDocuments, newItem]);
    } else {
      updateDirectories((prevDirectories) => [...prevDirectories, newItem]);
    }
    setShowAddPackageModal({ show: false, isDocument: false });
  };

  // Filter items based on the selected filter type
  const filteredItems = () => {
    if (filterType === "Directories") {
      return directories;
    } else if (filterType === "Documents") {
      return documents;
    } else {
      return [...directories, ...documents];
    }
  };

  // Pagination calculations for items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems().slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredItems().length / itemsPerPage);

  // Ensure current page is valid whenever filtered items change
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [filteredItems, totalPages]);

  // Pagination handlers for items
  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  // Handler for changing items per page
  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  // Handler for selecting an item to view details
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowDetails(true); // Default to showing details when an item is clicked
  };

  // Handler for navigating to item details on double click
  const handleItemDoubleClick = (item) => {
    if (directories.includes(item)) {
      navigate(`/directories/${item.reference}/`);
    } else {
      navigate(`/directories/${directoryPath}/${item.reference}/`);
    }
  };

  return (
    <Container fluid className="mb-4">
      <Row>
        <Col md={8} className="border-end">
          {/* Directories Section Header */}

          <div className="d-flex align-items-center justify-content-between mb-2">
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.5rem" }}
            >
              {params.directory ? (
                <NavLink
                  title="Back"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    borderRight: "solid 2px red",
                  }}
                  to={
                    parent && parent !== "null"
                      ? `/directories/${parent}/`
                      : "/directories/"
                  }
                >
                  <FaAngleLeft style={{ marginRight: "0.5rem" }} /> Back
                </NavLink>
              ) : (
                <NavLink
                className="active"
                  title="Back"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    borderRight: "solid 2px red",
                  }}
                  to={`/`}
                >
                  <FaAngleLeft style={{ marginRight: "0.5rem" }} /> Back
                </NavLink>
              )}
              <h4 className="mb-0">{title}</h4>
            </div>
          </div>

          <div
            className="d-flex align-items-center justify-content-between mb-4 mt-5"
            style={{ gap: "0.5rem" }}
          >
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.5rem" }}
            >
              <Dropdown onSelect={(e) => setFilterType(e)} className="me-2">
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="filter-type-dropdown"
                  size="sm"
                >
                  {filterType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  <Dropdown.Item eventKey="Directories">
                    Directories
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Documents">Documents</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {filteredItems().length > itemsPerPage && (
                <div
                  className="d-flex align-items-center me-2"
                  style={{ gap: "0.25rem" }}
                >
                  <Button
                    variant="secondary"
                    onClick={goToFirstPage}
                    disabled={currentPage === 1}
                    aria-label="First page"
                    size="sm"
                  >
                    <FaAngleLeft />
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                    aria-label="Previous page"
                    size="sm"
                  >
                    Prev
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                    aria-label="Next page"
                    size="sm"
                  >
                    Next
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={goToLastPage}
                    disabled={currentPage === totalPages}
                    aria-label="Last page"
                    size="sm"
                  >
                    <FaAngleRight />
                  </Button>
                </div>
              )}
              <Dropdown
                onSelect={(e) => handleItemsPerPageChange(Number(e))}
                className="me-2"
              >
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="items-per-page-dropdown"
                  size="sm"
                >
                  {itemsPerPage}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {itemsPerPageOptions.map((option) => (
                    <Dropdown.Item key={option} eventKey={option}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.5rem" }}
            >
              <Button
                variant="outline-primary"
                className="d-flex align-items-center"
                onClick={() => handleAdd(false)}
                size="sm"
              >
                <FaPlus style={{ marginRight: "0.25rem" }} /> Directory
              </Button>
              <Button
                variant="outline-primary"
                className="d-flex align-items-center ms-2"
                onClick={() => handleAdd(true)}
                size="sm"
              >
                <FaPlus style={{ marginRight: "0.25rem" }} /> Document
              </Button>
            </div>
          </div>

          <div className="mb-4">
            {`Showing ${indexOfFirstItem + 1} to ${Math.min(
              indexOfLastItem,
              filteredItems().length
            )} of ${filteredItems().length} items`}
          </div>

          {/* Items Content */}
          {filteredItems().length > 0 ? (
            <Row>
              {currentItems.map((item) => (
                <Col
                  key={item.reference}
                  sm={6}
                  md={4}
                  lg={3}
                  className="mb-4"
                  onClick={() => handleItemClick(item)}
                  onDoubleClick={() => handleItemDoubleClick(item)}
                >
                  <div
                    className="folder-file-container position-relative"
                    style={{
                      padding: "15px",
                      backgroundColor: "#f8f9fa",
                      textAlign: "center",
                      borderRadius: "8px",
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                      margin: "10px",
                      userSelect: 'none'
                    }}
                  >
                    {directories.includes(item) ? (
                      <FaFolder
                        size={80}
                        className="mb-2"
                        style={{ color: "#6c757d" }}
                      />
                    ) : (
                      <FaFileAlt
                        size={80}
                        className="mb-2"
                        style={{ color: "#6c757d" }}
                      />
                    )}
                    <div className="title small-text">
                      <small>{item.title}</small>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="d-flex justify-content-center align-items-center vh-100 text-center">
              <h4>No Items Found</h4>
            </div>
          )}
        </Col>
        <Col
          md={4}
          className="p-4 d-flex flex-column"
          style={{ height: "70vh", position: "sticky", top: "70px" }}
          ref={detailsRef}
        >
          {selectedItem && (
            <div className="details-section d-flex flex-column h-100">
              <div className="tabs-section mb-3 d-flex" style={{ gap: "1rem" }}>
                <Button
                  variant={showDetails ? "primary" : "outline-primary"}
                  onClick={() => setShowDetails(true)}
                  style={{ flex: 1 }}
                >
                  Details
                </Button>
                <Button
                  variant={!showDetails ? "primary" : "outline-primary"}
                  onClick={() => setShowDetails(false)}
                  style={{ flex: 1 }}
                >
                  Activity
                </Button>
              </div>
              <div className="details-content flex-grow-1 mb-3">
                {showDetails ? (
                  <div>
                    <p>
                      <strong>Title:</strong> {selectedItem.title}
                    </p>
                    <p>
                      <strong>Type:</strong>{" "}
                      {directories.includes(selectedItem) ? "Folder" : "File"}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>Activity information will be shown here...</p>
                  </div>
                )}
              </div>
              <div
                className="action-buttons d-flex flex-wrap justify-content-between"
                style={{
                  marginTop: "auto",
                  paddingTop: "10px",
                  gap: "0.25rem",
                }}
              >
                <Button
                  variant="outline-secondary"
                  className="btn-sm"
                  as={Link}
                  to={
                    directories.includes(selectedItem)
                      ? `/directories/${selectedItem.reference}/`
                      : `/directories/${directoryPath}/${selectedItem.reference}/`
                  }
                >
                  <FaFolderOpen style={{ marginRight: "0.25rem" }} /> Open
                </Button>
                <Button
                  variant="outline-primary"
                  className="btn-sm"
                  onClick={() => handleRename(selectedItem)}
                >
                  <FaEdit style={{ marginRight: "0.25rem" }} /> Rename
                </Button>
                <Button variant="outline-info" className="btn-sm">
                  <FaShare style={{ marginRight: "0.25rem" }} /> Share
                </Button>
                <Button variant="outline-success" className="btn-sm">
                  <FaDownload style={{ marginRight: "0.25rem" }} /> Download
                </Button>
                <Button
                  variant="outline-danger"
                  className="btn-sm"
                  onClick={() => handleDelete(selectedItem)}
                >
                  <FaTrash style={{ marginRight: "0.25rem" }} /> Delete
                </Button>
              </div>
            </div>
          )}
          {!selectedItem && (
            <h5 className="text-center">Select an item to see the details</h5>
          )}
        </Col>
      </Row>

      {/* Rename Modal */}
      <Modal
        show={showRenameModal}
        onHide={() => setShowRenameModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Rename Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>New Name</Form.Label>
              <Form.Control
                type="text"
                value={newDirectoryName}
                onChange={(e) => setNewDirectoryName(e.target.value)}
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Button
                variant="secondary"
                onClick={() => setShowRenameModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleRenameConfirm}
                disabled={!newDirectoryName}
              >
                Rename
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the item "{selectedDirectory?.title}"?
          This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Directory or Document Modal */}
      <DirectoryCreator
        showAddPackageModal={showAddPackageModal.show}
        setShowAddPackageModal={(show) =>
          setShowAddPackageModal({ ...showAddPackageModal, show })
        }
        isDocument={showAddPackageModal.isDocument}
        onAddPackageSuccess={handleAddPackageSuccess}
      />
    </Container>
  );
};

// PropTypes validation
DirectoryFolders.propTypes = {
  directories: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  parent: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  updateDirectories: PropTypes.func.isRequired,
  updateDocuments: PropTypes.func.isRequired,
};

export default DirectoryFolders;
