// src/context/ProcessingModal.js

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createContext, useContext, useState } from "react";
import Modal from "react-modal";

const ProcessingContext = createContext();

export function ProcessingProvider({ children }) {
  const [processing, setProcessing] = useState(false);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "100%",
      width: "500px",
    },
  };

  return (
    <ProcessingContext.Provider value={{ processing, setProcessing }}>
      {children}
      <Modal isOpen={processing} style={customStyles}>
        <div className="text-center bg-light p-4 rounded">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      </Modal>
    </ProcessingContext.Provider>
  );
}

export function useProcessing() {
  return useContext(ProcessingContext);
}
