import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { FaAngleUp, FaFolderPlus, FaFileAlt } from "react-icons/fa";
import { Button, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DirectoryCreator from "./DirectoryCreator";

const BlankPage = ({ parent, updateDocuments, updateDirectories }) => {
  const [showAddPackageModal, setShowAddPackageModal] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const params = useParams();

  // Handler for showing the modal to add a new directory or document
  const handleShowAddModal = (type) => {
    setIsDocument(type === "document");
    setShowAddPackageModal(true);
  };

  // Callback for successfully adding a new directory or document
  const handleAddPackageSuccess = (newPackage) => {
    if (newPackage.type === "document") {
      updateDocuments((prevItems) => [...prevItems, newPackage]);
    } else if (newPackage.type === "directory") {
      updateDirectories((prevItems) => [...prevItems, newPackage]);
    }
    setShowAddPackageModal(false);
  };

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center vh-75 text-center">
      <div className="mb-4 mt-5">
        <h2 className="text-secondary mb-5 mt-5">
          This area is empty. Let's create something!
        </h2>
        <Row className="g-4 justify-content-center">
          <Col xs="12" md="4">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={() => handleShowAddModal("directory")}
            >
              <FaFolderPlus style={{ marginRight: "10px" }} /> New Folder
            </Button>
          </Col>
          <Col xs="12" md="4">
            <Button
              variant="secondary"
              size="lg"
              className="w-100"
              onClick={() => handleShowAddModal("document")}
            >
              <FaFileAlt style={{ marginRight: "10px" }} /> New File
            </Button>
          </Col>
          {params?.directory && (
            <Col xs="12" md="4">
              <Link
                to={
                  parent && parent !== "null"
                    ? `/directories/${parent}/`
                    : "/directories/"
                }
                style={{ textDecoration: "none" }}
              >
                <Button variant="outline-dark" size="lg" className="w-100">
                  <FaAngleUp size={20} style={{ marginRight: "10px" }} /> Maybe
                  Later
                </Button>
              </Link>
            </Col>
          )}
        </Row>
      </div>

      {/* Add Directory or Document Modal */}
      <DirectoryCreator
        showAddPackageModal={showAddPackageModal}
        setShowAddPackageModal={setShowAddPackageModal}
        isDocument={isDocument}
        onAddPackageSuccess={handleAddPackageSuccess}
      />
    </Container>
  );
};

BlankPage.propTypes = {
  parent: PropTypes.string,
  updateDocuments: PropTypes.func.isRequired,
  updateDirectories: PropTypes.func.isRequired,
};

export default BlankPage;
