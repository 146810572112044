import { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  BlockQuote,
  BlockToolbar,
  Bold,
  Code,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Mention,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Style,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Title,
  TodoList,
  Underline,
  Undo,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { Row, Col, Button, Container } from "react-bootstrap";
import {
  FaEdit,
  FaDownload,
  FaTrash,
  FaShare,
  FaPaperclip,
  FaAngleLeft,
} from "react-icons/fa";
import {NavLink, useParams } from "react-router-dom";

const DocumentEditor = () => {
  const [document, setDocument] = useState(null);
  const [showDetails, setShowDetails] = useState(true);
  const params = useParams();
  let directoryPath = `${
    params.directory !== "directory" ? params.directory : ""
  }`;

  directoryPath =
    directoryPath && !directoryPath.endsWith("/")
      ? `${directoryPath}/`
      : directoryPath;

      const editorRef = useRef(null);
      const [isLayoutReady, setIsLayoutReady] = useState(false);
    
      useEffect(() => {
        setIsLayoutReady(true);
    
        return () => setIsLayoutReady(false);
      }, []);
    
      const editorConfig = {
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "findAndReplace",
            "|",
            "heading",
            "style",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "subscript",
            "superscript",
            "code",
            "removeFormat",
            "|",
            "specialCharacters",
            "horizontalLine",
            "pageBreak",
            "link",
            "insertImage",
            "insertImageViaUrl",
            "insertTable",
            "highlight",
            "blockQuote",
            "|",
            "alignment",
            "|",
            "bulletedList",
            "numberedList",
            "todoList",
            "outdent",
            "indent",
          ],
          shouldNotGroupWhenFull: false,
        },
        plugins: [
          AccessibilityHelp,
          Alignment,
          Autoformat,
          AutoImage,
          AutoLink,
          Autosave,
          BalloonToolbar,
          Base64UploadAdapter,
          BlockQuote,
          BlockToolbar,
          Bold,
          Code,
          Essentials,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          GeneralHtmlSupport,
          Heading,
          Highlight,
          HorizontalLine,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsert,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          Mention,
          PageBreak,
          Paragraph,
          PasteFromOffice,
          RemoveFormat,
          SelectAll,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Style,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          Title,
          TodoList,
          Underline,
          Undo,
        ],
        removePlugins: ["CKEditor5UI"],
        balloonToolbar: [
          "bold",
          "italic",
          "|",
          "link",
          "insertImage",
          "|",
          "bulletedList",
          "numberedList",
        ],
        blockToolbar: [
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertImage",
          "insertTable",
          "|",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
        ],
        fontFamily: {
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22],
          supportAllValues: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true,
            },
          ],
        },
        image: {
          toolbar: [
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "imageStyle:inline",
            "imageStyle:wrapText",
            "imageStyle:breakText",
            "|",
            "resizeImage",
          ],
        },
        initialData: `<h2>Start</h2> <hr />`,
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: "https://",
          decorators: {
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file",
              },
            },
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        mention: {
          feeds: [
            {
              marker: "@",
              feed: [
                /* See: https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html */
              ],
            },
          ],
        },
        menuBar: {
          isVisible: true,
        },
        placeholder: "Type or paste your content here!",
        style: {
          definitions: [
            {
              name: "Article category",
              element: "h3",
              classes: ["category"],
            },
            {
              name: "Title",
              element: "h2",
              classes: ["document-title"],
            },
            {
              name: "Subtitle",
              element: "h3",
              classes: ["document-subtitle"],
            },
            {
              name: "Info box",
              element: "p",
              classes: ["info-box"],
            },
            {
              name: "Side quote",
              element: "blockquote",
              classes: ["side-quote"],
            },
            {
              name: "Marker",
              element: "span",
              classes: ["marker"],
            },
            {
              name: "Spoiler",
              element: "span",
              classes: ["spoiler"],
            },
            {
              name: "Code (dark)",
              element: "pre",
              classes: ["fancy-code", "fancy-code-dark"],
            },
            {
              name: "Code (bright)",
              element: "pre",
              classes: ["fancy-code", "fancy-code-bright"],
            },
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
      };
    
  useEffect(() => {
    // Simulate API call to fetch document data
    const fetchedDocument = {
      reference: "1",
      title: "Sample Document",
      content:
        "<p>This is a sample document with <strong>HTML content</strong> to display.</p><p>It contains paragraphs, bold text, and other HTML elements.</p>",
      attachments: [
        { name: "Attachment1.pdf" },
        { name: "Attachment2.docx" },
        { name: "Attachment3.xlsx" },
      ],
    };
    setDocument(fetchedDocument);
  }, []);

  const handleDelete = () => {
    // Simulate document deletion
    setDocument(null);
  };

  return (
    <Container fluid><Row className="mb-4 align-items-center">
        <Col className="d-flex align-items-center">
          <NavLink
            to={`/directories/${directoryPath}${params.document}/`}
            className="d-flex align-items-center"
            style={{
              textDecoration: "none",
              color: "inherit",
              borderRight: "solid 2px red",
            }}
          >
            <FaAngleLeft style={{ marginRight: "0.5rem" }} /> Back
          </NavLink>
          <h4 className="ms-3 mb-0">Document Editor</h4>
        </Col>
      </Row>
      <Row>
        <Col md={8} className="p-4 border-end">
        
          {document ? (
  <div
  className="editor-container editor-container_classic-editor editor-container_include-style editor-container_include-block-toolbar"
  ref={editorRef}
>
  <div className="editor-container__editor">
    {isLayoutReady && (
      <CKEditor
        editor={ClassicEditor}
        config={editorConfig}
        data={``}
        onChange={``}
      />
    )}
  </div>
</div>
          ) : (
            <div className="text-center">
              <h5>The document has been deleted</h5>
            </div>
          )}
        </Col>

        <Col
          md={4}
          className="p-4"
          style={{
            height: "70vh",
            position: "sticky",
            top: "70px",
            borderLeft: "1px solid #ccc",
          }}
        >
          {document && (
            <div className="details-section d-flex flex-column h-100">
              <div className="tabs-section mb-3 d-flex" style={{ gap: "1rem" }}>
                <Button
                  variant={showDetails ? "primary" : "outline-primary"}
                  onClick={() => setShowDetails(true)}
                  style={{ flex: 1 }}
                >
                  Details
                </Button>
                <Button
                  variant={!showDetails ? "primary" : "outline-primary"}
                  onClick={() => setShowDetails(false)}
                  style={{ flex: 1 }}
                >
                  Activity
                </Button>
                <Button variant="success" style={{ flex: 1 }}>
                  Mark As Read
                </Button>
              </div>
              <div className="details-content flex-grow-1 mb-3">
                {showDetails ? (
                  <div>
                    <p>
                      <strong>Title:</strong> {document.title}
                    </p>
                    <p>
                      <strong>Type:</strong> File
                    </p>
                    <p>
                      <strong>Content:</strong> A detailed description of the
                      document will go here...
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>Activity information will be shown here...</p>
                  </div>
                )}
              </div>
              <div
                className="action-buttons d-flex flex-wrap justify-content-between"
                style={{ marginTop: "auto", gap: "0.5rem" }}
              >
                <Button
                  size="sm"
                  variant="outline-info"
                  className="flex-grow-1 d-flex align-items-center justify-content-center"
                  title="Share Document"
                >
                  <FaShare />
                </Button>
                <NavLink
                to={`/directories/${params.directory}/${params.document}/editor/`}
                  size="sm"
                  className="bg-success flex-grow-1 btn d-flex align-items-center justify-content-center"
                  title="Edit Online"
                >
                  <FaEdit />
                </NavLink>
                <Button
                  size="sm"
                  variant="outline-success"
                  className="flex-grow-1 d-flex align-items-center justify-content-center"
                  title="Download Document"
                >
                  <FaDownload />
                </Button>
                <Button
                  size="sm"
                  variant="outline-warning"
                  className="flex-grow-1 d-flex align-items-center justify-content-center"
                  title="Add Attachment"
                >
                  <FaPaperclip />
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  className="flex-grow-1 d-flex align-items-center justify-content-center"
                  onClick={handleDelete}
                  title="Delete Document"
                >
                  <FaTrash />
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>

    </Container>
  );
};

export default DocumentEditor;
