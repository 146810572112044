// src/components/Restricted.js

import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { FiAlertCircle } from "react-icons/fi";
import { Helmet } from "react-helmet";

/**
 * Restricted component displays an alert indicating that the user's subscription has restricted
 * and provides a button to navigate to the subscription renewal page.
 *
 * @param {Object} props - Component props
 * @param {string} props.product - The product identifier for the subscription renewal
 * @returns {JSX.Element} - The subscription restricted component.
 */
const Restricted = ({ product }) => {
  const navigate = useNavigate();

  /**
   * Handles navigation to the subscription renewal page.
   */
  const handleNavigate = () => {
    navigate(`/products/${product}/`);
  };

  return (
    <Container className="mt-5">
      <Helmet>
        <title>Restricted - Care Quality Support</title>
      </Helmet>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Alert variant="danger" className="mb-4">
            <Alert.Heading className="d-flex justify-content-between align-items-center">
              Restricted Access
              <FiAlertCircle />
            </Alert.Heading>
            <p className="mb-4">
              Your subscription is either expired or not valid.
            </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="primary" onClick={handleNavigate}>
                Request Access
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

Restricted.propTypes = {
  product: PropTypes.string.isRequired,
};

export default Restricted;
