import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Form,
  Modal,
  Pagination,
  Breadcrumb,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Sample data including replies and attachments
const sampleRequests = [
  {
    id: 1,
    subject: "Issue with login",
    date: "2024-10-01",
    status: "Pending",
    priority: "High",
    message: "I can't login to my account.",
    replies: [
      { id: 1, sender: "User", message: "I can't login to my account.", timestamp: "2024-10-01 10:00" },
      { id: 2, sender: "Support", message: "Have you tried resetting your password?", timestamp: "2024-10-01 12:30" },
    ],
    attachments: [],
  },
  {
    id: 2,
    subject: "Unable to download invoice",
    date: "2024-09-25",
    status: "Resolved",
    priority: "Medium",
    message: "Can't download my invoice.",
    replies: [
      { id: 1, sender: "User", message: "Can't download my invoice.", timestamp: "2024-09-25 09:45" },
      { id: 2, sender: "Support", message: "Please try again, we've fixed the issue.", timestamp: "2024-09-25 10:30" },
    ],
    attachments: [
      { filename: "invoice_screenshot.png", uploadedAt: "2024-09-25 09:50" },
    ],
  },
];

const SupportPage = () => {
  const [requests, setRequests] = useState(sampleRequests);
  const [newRequest, setNewRequest] = useState({ subject: "", message: "", priority: "Low" });
  const [currentRequest, setCurrentRequest] = useState(null);
  const [showNewRequestModal, setShowNewRequestModal] = useState(false);
  const [showViewRequestModal, setShowViewRequestModal] = useState(false);
  const [replyMessage, setReplyMessage] = useState(""); // New reply
  const [replyAttachment, setReplyAttachment] = useState(null); // Reply attachment
  const [currentReplyIndex, setCurrentReplyIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  // Handle form input changes for new request
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRequest((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Submit new request
  const handleSubmitRequest = (e) => {
    e.preventDefault();
    const newId = requests.length + 1;
    const newEntry = {
      id: newId,
      subject: newRequest.subject,
      date: new Date().toISOString().split("T")[0],
      status: "Pending",
      priority: newRequest.priority,
      message: newRequest.message,
      replies: [{ id: 1, sender: "User", message: newRequest.message, timestamp: new Date().toLocaleString() }],
      attachments: [],
    };
    setRequests((prevRequests) => [...prevRequests, newEntry]);
    setShowNewRequestModal(false);
    setNewRequest({ subject: "", message: "", priority: "Low" });
  };

  // Open view modal for a request
  const handleViewRequest = (request) => {
    setCurrentRequest(request);
    setCurrentReplyIndex(request.replies.length - 1);
    setShowViewRequestModal(true);
  };

  // Submit a reply
  const handleReplySubmit = () => {
    const updatedRequest = {
      ...currentRequest,
      replies: [
        ...currentRequest.replies,
        { id: currentRequest.replies.length + 1, sender: "User", message: replyMessage, timestamp: new Date().toLocaleString() },
      ],
    };

    if (replyAttachment) {
      updatedRequest.attachments.push({
        filename: replyAttachment.name,
        uploadedAt: new Date().toLocaleString(),
      });
    }

    setRequests((prevRequests) =>
      prevRequests.map((req) => (req.id === updatedRequest.id ? updatedRequest : req))
    );
    setCurrentRequest(updatedRequest);
    setReplyMessage(""); // Clear reply message
    setReplyAttachment(null); // Clear attachment
  };

  // Upload attachment
  const handleAttachmentChange = (e) => {
    setReplyAttachment(e.target.files[0]);
  };

  // Navigate between replies
  const handleNextReply = () => {
    if (currentReplyIndex < currentRequest.replies.length - 1) {
      setCurrentReplyIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousReply = () => {
    if (currentReplyIndex > 0) {
      setCurrentReplyIndex((prevIndex) => prevIndex - 1);
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(requests.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRequests = requests.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <React.Fragment>
      <Helmet>
        <title>Request Support - Care Quality Support</title>
      </Helmet>

      <Container className="mb-4">
        <Row className="align-items-center mb-4">
          <Col className="mt-4">
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Request Support</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="text-end mb-3">
            <Button variant="primary" onClick={() => setShowNewRequestModal(true)}>
              Submit New Request
            </Button>
          </Col>

          <Col md={12}>
            <Card>
              <Card.Header as="h5">Your Support Requests</Card.Header>
              <Card.Body>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Subject</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Priority</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRequests.map((request) => (
                      <tr key={request.id}>
                        <td>{request.id}</td>
                        <td>{request.subject}</td>
                        <td>{new Date(request.date).toLocaleDateString()}</td>
                        <td>{request.status}</td>
                        <td>{request.priority}</td>
                        <td>
                          <Button
                            variant="outline-primary"
                            onClick={() => handleViewRequest(request)}
                          >
                            View Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {requests.length > itemsPerPage && (
                  <Pagination className="justify-content-center mt-4">
                    <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
                    <Pagination.Prev onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                    {[...Array(totalPages)].map((_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                    <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
                  </Pagination>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Submit New Request Modal */}
      <Modal show={showNewRequestModal} onHide={() => setShowNewRequestModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Submit New Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitRequest}>
            <Form.Group className="mb-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                name="subject"
                value={newRequest.subject}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Priority</Form.Label>
              <Form.Select
                name="priority"
                value={newRequest.priority}
                onChange={handleInputChange}
                required
              >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                rows={3}
                value={newRequest.message}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="secondary" onClick={() => setShowNewRequestModal(false)}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" className="ms-2">
              Submit Request
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* View Request Modal */}
      {currentRequest && (
        <Modal show={showViewRequestModal} onHide={() => setShowViewRequestModal(false)} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>{currentRequest.subject}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Table bordered>
              <tbody>
                <tr>
                  <td><strong>Subject</strong></td>
                  <td>{currentRequest.subject}</td>
                </tr>
                <tr>
                  <td><strong>Date</strong></td>
                  <td>{new Date(currentRequest.date).toLocaleDateString()}</td>
                </tr>
                <tr>
                  <td><strong>Status</strong></td>
                  <td>{currentRequest.status}</td>
                </tr>
                <tr>
                  <td><strong>Priority</strong></td>
                  <td>{currentRequest.priority}</td>
                </tr>
              </tbody>
            </Table>

            <p><strong>Initial Request:</strong></p>
            <p>{currentRequest.message}</p>

            {currentRequest.attachments.length > 0 && (
              <>
                <p><strong>Attachments:</strong></p>
                <ul>
                  {currentRequest.attachments.map((att, index) => (
                    <li key={index}><a href="#">{att.filename}</a> <small>({att.uploadedAt})</small></li>
                  ))}
                </ul>
              </>
            )}

            {/* Current Reply */}
            {currentRequest.replies && currentRequest.replies.length > 0 && (
              <div>
                <p>
                  <strong>{currentRequest.replies[currentReplyIndex].sender}:</strong>{" "}
                  <span className="text-muted">({currentRequest.replies[currentReplyIndex].timestamp})</span>
                </p>
                <p>{currentRequest.replies[currentReplyIndex].message}</p>
              </div>
            )}

            {/* Reply Navigation */}
            <div className="d-flex justify-content-between my-3">
              <Button variant="outline-secondary" onClick={handlePreviousReply} disabled={currentReplyIndex === 0}>
                Previous Response
              </Button>
              <Button variant="outline-secondary" onClick={handleNextReply} disabled={currentReplyIndex === currentRequest.replies.length - 1}>
                Next Response
              </Button>
            </div>

            {/* Add a new reply */}
            <Form.Group className="mt-4">
              <Form.Label>Add a Reply</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
                placeholder="Write your reply here..."
              />
              <Form.Label className="mt-2">Attachment</Form.Label>
              <Form.Control type="file" onChange={handleAttachmentChange} />
              <Button className="mt-3" variant="primary" onClick={handleReplySubmit}>
                Send Reply
              </Button>
            </Form.Group>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default SupportPage;
