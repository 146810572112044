// src/components/AccessWrappers.js

import React from "react";
import useSlot from "../hooks/useSlot";

/**
 * Factory function to create access wrappers based on allowed slots.
 *
 * @param {number[]} allowedSlots - Array of allowed slot numbers.
 * @returns {ReactNode} Component that conditionally renders based on user slot.
 */
const createAccessWrapper = (allowedSlots) => {
  return ({ children }) => {
    const slot = useSlot();
    return allowedSlots.includes(slot) ? children : null;
  };
};

// Define access wrappers for different role groups
export const Admin = createAccessWrapper([1]); // Core admin role
export const Owner = createAccessWrapper([1, 2]); // Owner role includes Admin
export const Manager = createAccessWrapper([1, 2, 3]); // Manager role includes Owner
export const Finance = createAccessWrapper([1, 2, 3, 4]); // Finance-related access
export const Supervisor = createAccessWrapper([1, 2, 3, 5]); // Supervisor with specific permissions
export const TeamMember = createAccessWrapper([1, 2, 3, 4, 5, 6]); // Team-wide access

// Customer-related access roles
export const CustomerOwner = createAccessWrapper([1, 7]); // Base customer role for owners
export const CustomerManager = createAccessWrapper([1, 7, 8]); // Customer manager role
export const CustomerSupervisor = createAccessWrapper([1, 7, 8, 9]); // Supervisory role for customer management
export const CustomerTeam = createAccessWrapper([1, 7, 8, 9, 10]); // Full customer team access

// Admin with extended customer-related permissions
export const Management = createAccessWrapper([1, 2, 3, 7, 8]); // Admin role with customer-specific access

export const EditorTeam = createAccessWrapper([1, 2, 3, 4, 5, 6, 7, 8]); // Team-wide access

export const CustomerTeamOnly = createAccessWrapper([1, 9, 10]); // Team-wide access