// /components/LazyImage.js
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const LazyImage = ({ src, alt, style, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Stop observing once the image is loaded
          }
        });
      },
      {
        threshold: 0.1, // Trigger when at least 10% of the image is visible
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    // Cleanup function to disconnect the observer on component unmount
    return () => {
      if (observer && imgRef.current) {
        observer.disconnect();
      }
    };
  }, [imgRef]);

  return (
    <img
      ref={imgRef}
      src={isVisible ? src : undefined} // Load image only when it becomes visible
      alt={alt}
      style={style}
      className={className}
      loading="lazy" // Additional hint to browsers to load lazily
    />
  );
};

// PropTypes validation
LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

LazyImage.defaultProps = {
  alt: "Lazy loaded image", // Default alt text
  style: {},
  className: "",
};

export default LazyImage;
