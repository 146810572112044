// File path: /src/utils/autoLogout.js

import { localData } from "../context/processor";
import { logout, isAuthenticated } from "./auth";

let timeoutId;
const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

/**
 * Resets the inactivity timeout and updates the session expiry time.
 * @param {function} showWarningModal - Function to show the warning modal before auto logout.
 */
const resetTimeout = (showWarningModal) => {
  const session = localData("get", "session");
  const expiry = localData("get", "expiry");

  if (!session || !expiry) return;

  const currentTime = new Date().getTime();
  const timeoutMinutes = session.timeout;
  const newExpiryTime = currentTime + timeoutMinutes * 60 * 1000;

  localData("save", "expiry", newExpiryTime);

  clearTimeout(timeoutId);
  timeoutId = setTimeout(showWarningModal, (timeoutMinutes - 2) * 60 * 1000);
};

/**
 * Starts the auto logout mechanism by setting up event listeners and the initial timeout.
 * @param {function} showWarningModal - Function to show the warning modal before auto logout.
 */
const startAutoLogout = (showWarningModal) => {
  if (!isAuthenticated()) {
    logout();
    return;
  }

  resetTimeout(showWarningModal);

  const resetHandler = () => resetTimeout(showWarningModal);

  events.forEach((event) => window.addEventListener(event, resetHandler));
};

/**
 * Stops the auto logout mechanism by clearing the timeout and removing event listeners.
 */
const stopAutoLogout = () => {
  clearTimeout(timeoutId);
  events.forEach((event) => window.removeEventListener(event, resetTimeout));
};

export { startAutoLogout, stopAutoLogout };
