import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { runData } from "../context/processor";
import DOMPurify from "dompurify";
import Restricted from "../includes/Restricted";
import ErrorPage from "../includes/ErrorPage";
import Processing from "../includes/Processing";
import useSlot from "../hooks/useSlot";
import DirectoryFolders from "./DirectoryFolders";
import DirectoryCreator from "./DirectoryCreator";
import BlankPage from "./DirectoryBlank";

const Directories = () => {
  const updateDocuments = (newDocument) => {
    console.log(newDocument);

    setState((prevState) => ({
      ...prevState,
      documents: [...prevState.documents, newDocument],
    }));
  };
  const [state, setState] = useState({
    loading: true,
    error: null,
    directories: [],
    documents: [],
    isRestricted: false,
  });
  const location = useLocation();
  const { directory } = useParams(); // Extract directory parameter from the URL
  const [showAddPackageModal, setShowAddPackageModal] = useState(false); // State for Add Directory Modal
  const [isDocument, setIsDocument] = useState(false); // State for adding document or directory
  const [pageTitle, setPageTitle] = useState("Opening"); // Directory title
  const [pageParent, setPageParent] = useState(null); // Directory parent
  const slot = useSlot(); // Get user's slot information

  const updateIsDocument = (value) => {
    setIsDocument(value);
  };

  useEffect(() => {
    const fetchDirectoriesData = async () => {
      try {
        const parent = directory || "root"; // Determine if parent is root or specific directory
        const data = {
          parent: parent,
        };
        const responseData = await runData(data, `/api/directories/`);

        if (responseData?.data?.code === 200) {
          setPageTitle(responseData.data.title || "Directories");
          setPageParent(responseData.data.parent);
          const sanitizedDirectories = responseData.data.directories
            .filter((pkg) => pkg.accessType !== "global" || slot < 7)
            .map((pkg) => ({
              ...pkg,
              title: DOMPurify.sanitize(pkg.title),
              reference: DOMPurify.sanitize(pkg.reference),
              accessType: pkg.accessType,
            }));

          const sanitizedDocuments = responseData.data.documents
            .filter((pkg) => pkg.accessType !== "global" || slot < 7)
            .map((pkg) => ({
              ...pkg,
              title: DOMPurify.sanitize(pkg.title),
              reference: DOMPurify.sanitize(pkg.reference),
              accessType: pkg.accessType,
            }));

          setState((prevState) => ({
            ...prevState,
            directories: sanitizedDirectories,
            documents: sanitizedDocuments,
            loading: false,
          }));
        } else if (responseData.data) {
          setPageTitle("Error");
          setState((prevState) => ({
            ...prevState,
            error: DOMPurify.sanitize(responseData.data.message),
            loading: false,
          }));
        } else if (responseData.error) {
          setPageTitle("Error");
          setState((prevState) => ({
            ...prevState,
            error: DOMPurify.sanitize(responseData.error.message),
            loading: false,
          }));
        }
      } catch (err) {
        setPageTitle("Error");
        setState((prevState) => ({
          ...prevState,
          isRestricted: err.status === 402,
          error: err.status !== 402 ? DOMPurify.sanitize(err.message) : null,
          loading: false,
        }));
      }
    };

    fetchDirectoriesData();
  }, [location, directory, slot]);

  const { directories, documents, loading, error, isRestricted } = state;

  const handleAddPackageSuccess = (newPackage) => {
    if (newPackage.type === "directory") {
      updateDirectories(newPackage);
    } else if (newPackage.type === "document") {
      updateDocuments(newPackage);
    }
  };

  const updateDirectories = (newDirectory) => {
    setState((prevState) => ({
      ...prevState,
      directories: [...prevState.directories, newDirectory],
    }));
  };

  if (loading) {
    return <Processing />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isRestricted) {
    return <Restricted product="" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle} - Care Quality Support</title>
      </Helmet>
      <Container className="mb-4 mt-4" fluid>
        <Row>
          {/* Main Area */}
          <Col>
            {directories.length === 0 && documents.length === 0 ? (
              <BlankPage
                parent={pageParent}
                updateDocuments={updateDocuments}
                updateDirectories={updateDirectories}
              />
            ) : (
                <DirectoryFolders
                  directories={directories}
                  updateDirectories={updateDirectories}
                  parent={pageParent}
                  title={pageTitle}
                  documents={documents}
                  updateDocuments={updateDocuments}
                />
            )}
          </Col>
        </Row>
        <DirectoryCreator
          showAddPackageModal={showAddPackageModal}
          setShowAddPackageModal={setShowAddPackageModal}
          isDocument={isDocument}
          updateIsDocument={updateIsDocument}
          onAddPackageSuccess={handleAddPackageSuccess}
        />
      </Container>
    </React.Fragment>
  );
};

export default Directories;
