import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "./Layout";
import NotFound from "../templates/NotFound";
import Login from "../templates/Login";
import Recover from "../templates/Recover";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import WarningModal from "../includes/WarningModal";
import { startAutoLogout, stopAutoLogout } from "../utils/autoLogout";
import { logout, isAuthenticated, onAuthChange } from "../utils/auth";
import Logout from "../templates/Logout";
import { localData, runData } from "../context/processor";
import Directories from "../directories/Directories";
import ChatPage from "../composer/ChatPage";
import Customers from "../customers/customers";
import CustomerPage from "../customers/customer";
import UserActivitiesPage from "../team/UserActivities";
import UserEventsPage from "../team/UserHistory";
import OverviewPage from "../dashboard/overview";

import ProfileDisplay from "../account/profile";

import SupportPage from "../support/support";

import Shortcodes from "../dashboard/shortcodes";
import Dashboard from "../dashboard/Dashboard";
import FullFeaturedEditor from "../templates/Editor";
import DocumentDetails from "../directories/DirectoryFile";
import DocumentEditor from "../directories/DirectoryFileEditor";

/**
 * Main App component that handles routing and authentication.
 * @component
 * @returns {JSX.Element}
 */
const App = () => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [authenticated, setAuthenticated] = useState(isAuthenticated());

  /**
   * Shows the warning modal.
   */
  const handleShowWarningModal = () => setShowWarningModal(true);

  /**
   * Hides the warning modal.
   */
  const handleHideWarningModal = () => setShowWarningModal(false);

  /**
   * Logs the user out and stops auto logout.
   */
  const handleLogout = () => {
    handleHideWarningModal();
    stopAutoLogout();
    logout();
  };

  /**
   * Shows a reload alert using SweetAlert2 and reloads the page.
   */
  const showReloadAlert = () => {
    Swal.fire({
      title: "Rights Changed",
      text: "Your rights have changed, you must reload.",
      icon: "warning",
      confirmButtonText: "OK",
    }).then(() => {
      window.location.reload();
    });
  };

  const showLogoutAlert = () => {
    Swal.fire({
      title: "Session Error",
      text: "Your session has been aborted, you must login again.",
      icon: "warning",
      confirmButtonText: "OK",
    }).then(() => {
      logout();
    });
  };

  /**
   * Keeps the session alive by sending a request to the server.
   * @async
   */
  const keepAlive = async () => {
    const data = {};
    try {
      const responseData = await runData(data, `/api/keep-alive/`);
      if (responseData.data) {
        const savedSlot = localData("get", "slot");
        if (savedSlot !== responseData.data.slot) {
          showReloadAlert();
        }
        localData("update", "slot", responseData.data.slot);
      } else {
        showLogoutAlert();
      }
    } catch (error) {
      showLogoutAlert();
    }
  };

  useEffect(() => {
    /**
     * Handles authentication state changes.
     * @param {boolean} newAuthState - The new authentication state.
     */
    const handleAuthChange = (newAuthState) => {
      setAuthenticated(newAuthState);
    };

    const unsubscribe = onAuthChange(handleAuthChange);

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (authenticated) {
      keepAlive();
      startAutoLogout(handleShowWarningModal);
      const intervalId = setInterval(keepAlive, 60000);
      return () => clearInterval(intervalId);
    }
  }, [authenticated]);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/login/" element={<PublicRoute element={<Login />} />} />
          <Route
            path="/login/:token/"
            element={<PublicRoute element={<Recover />} />}
          />

          <Route
            exact
            path="/"
            element={<ProtectedRoute element={<Dashboard />} />}
          />

          <Route
            path="/logout/"
            element={<ProtectedRoute element={<Logout />} />}
          />
          <Route
            path="/directories/"
            element={<ProtectedRoute element={<Directories />} />}
          />
          <Route
            path="/directories/:directory/"
            element={<ProtectedRoute element={<Directories />} />}
          />

          <Route
            path="/directories/:directory/:document/"
            element={<ProtectedRoute element={<DocumentDetails />} />}
          />

          <Route
            path="/directories/:directory/:document/editor/"
            element={<ProtectedRoute element={<DocumentEditor />} />}
          />

          <Route
            path="/profile/"
            element={<ProtectedRoute element={<ProfileDisplay />} />}
          />
          <Route
            path="/notifications/"
            element={<ProtectedRoute element={<ProfileDisplay />} />}
          />
          <Route
            path="/settings/"
            element={<ProtectedRoute element={<OverviewPage />} />}
          />
          <Route
            path="/placeholders/"
            element={<ProtectedRoute element={<Shortcodes />} />}
          />

          <Route
            path="/team/"
            element={<ProtectedRoute element={<UserActivitiesPage />} />}
          />

          <Route
            path="/team/:user/"
            element={<ProtectedRoute element={<UserEventsPage />} />}
          />
          <Route
            path="/clients/"
            element={<ProtectedRoute element={<Customers />} />}
          />

          <Route
            path="/clients/:customer/"
            element={<ProtectedRoute element={<CustomerPage />} />}
          />

          <Route
            path="/composer/"
            element={<ProtectedRoute element={<ChatPage />} />}
          />
          <Route
            path="/support/"
            element={<ProtectedRoute element={<SupportPage />} />}
          />
          <Route path="*" element={<ProtectedRoute element={<NotFound />} />} />
        </Routes>
      </Layout>
      <WarningModal
        show={showWarningModal}
        onReset={handleHideWarningModal}
        onLogout={handleLogout}
      />
    </Router>
  );
};

export default App;
