// Processing.js

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * Processing component renders a spinner to indicate loading state.
 *
 * @returns {JSX.Element} The rendered component
 */
function Processing() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Helmet>
        <title>Processing - Care Quality Support</title>
      </Helmet>
      <div>
        <FontAwesomeIcon icon={faSpinner} size="5x" spin />
      </div>
    </div>
  );
}

export default Processing;
