import React from "react";
import { Card, Container, Row, Col, ListGroup, Breadcrumb, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

// Sample user data for now
const sampleUser = {
  reference: "USR001",
  profile: "Admin",
  company: "XYZ Corporation",
  title: "Mr.",
  name: "John",
  surname: "Doe",
  email: "john.doe@xyzcorp.com",
  phone: "+123456789",
  status: 1, // Active
};

const ProfileDisplay = () => {
  const user = sampleUser; // For now, we're using the sample user data

  return (
    <React.Fragment>
      <Helmet>
        <title>{user.name}'s Profile - Care Quality Support</title>
      </Helmet>
      <Container className="mb-4">
        <Row className="align-items-center mb-4">
          <Col className="mt-4">
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Profile</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row>
          {/* Sidebar */}
          <Col md={3}>
            <Card>
              <Card.Header as="h5">Profile Navigation</Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item as={Link} to="/profile/display" active>
                  Profile
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/profile/settings">
                  Settings
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

          {/* Profile Details */}
          <Col md={9}>
            <Card>
              <Card.Header as="h5" className="d-flex justify-content-between align-items-center">
                Your Profile
                <Button
                  variant="outline-primary"
                  as={Link}
                  to="/profile/settings"
                  title="Edit Profile Settings"
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </Button>
              </Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item><strong>Reference:</strong> {user.reference}</ListGroup.Item>
                  <ListGroup.Item><strong>Profile:</strong> {user.profile}</ListGroup.Item>
                  <ListGroup.Item><strong>Company:</strong> {user.company}</ListGroup.Item>
                  <ListGroup.Item><strong>Title:</strong> {user.title}</ListGroup.Item>
                  <ListGroup.Item><strong>Name:</strong> {user.name}</ListGroup.Item>
                  <ListGroup.Item><strong>Surname:</strong> {user.surname}</ListGroup.Item>
                  <ListGroup.Item><strong>Email:</strong> {user.email}</ListGroup.Item>
                  <ListGroup.Item><strong>Phone:</strong> {user.phone}</ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Status:</strong> {user.status === 1 ? "Active" : "Inactive"}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ProfileDisplay;
