import React, { useEffect, Suspense } from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";
import loadIcon from "../utils/loadIcon"; // Dynamic icon loading
import { runData } from "../context/processor";

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const chartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Reading Data",
        data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 39],
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  };

  const insights = [
    "Insight 1: Sample data insight",
    "Insight 2: Another key metric",
    "Insight 3: Important information",
  ];

  // Fetch dashboard data
  const fetchData = async () => {
    try {
      const data = { page: "Dashboard" };
      const responseData = await runData(data, `/dashboard/`);
      if (responseData.data) {
        const { code, message } = responseData.data;
        if (code !== 200) {
          console.error(`Error: ${message}`);
        }
      } else if (responseData.error) {
        console.error(`Error: ${responseData.error}`);
      } else {
        throw new Error("Unexpected response structure");
      }
    } catch (err) {
      console.error("Error fetching data:", DOMPurify.sanitize(err.message));
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container className="py-4">
      <Helmet>
        <title>Dashboard - Care Quality Support</title>
      </Helmet>

      {/* Dashboard Header */}
      <Row className="align-items-center mb-4">
        <Col>
          <h4 className="dashboard-title">Dashboard</h4>
        </Col>
      </Row>

      {/* Summary Cards */}
      <Row className="mb-4">
        <Col md={3} className="mb-3">
          <Link to={"/packages/"} className="text-decoration-none text-body">
            <Card className="dashboard-card bg-success hover-zoom hover-shadow">
              <Card.Body className="d-flex align-items-center">
                <Suspense fallback={<span>Loading...</span>}>
                  {React.createElement(loadIcon("FaClipboardList"), {
                    size: 40,
                    className: "me-3 text-primary",
                  })}
                </Suspense>
                <div>
                  <h5 className="text-white">Policies</h5>
                  <p className="text-white-50">Our Policies</p>
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={3} className="mb-3">
          <Link to={"/templates/"} className="text-decoration-none text-body">
            <Card className="dashboard-card bg-info hover-zoom hover-shadow">
              <Card.Body className="d-flex align-items-center">
                <Suspense fallback={<span>Loading...</span>}>
                  {React.createElement(loadIcon("FaFileAlt"), {
                    size: 40,
                    className: "me-3 text-primary",
                  })}
                </Suspense>
                <div>
                  <h5 className="text-white">Templates</h5>
                  <p className="text-white-50">Our Templates</p>
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={3} className="mb-3">
          <Link
            to={"/organisation/"}
            className="text-decoration-none text-body"
          >
            <Card className="dashboard-card bg-secondary hover-zoom hover-shadow">
              <Card.Body className="d-flex align-items-center">
                <Suspense fallback={<span>Loading...</span>}>
                  {React.createElement(loadIcon("FaBuilding"), {
                    size: 40,
                    className: "me-3 text-primary",
                  })}
                </Suspense>
                <div className="text-light">
                  <h5>Organisation</h5>
                  <p>Our Organisation</p>
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={3} className="mb-3">
          <Link to={"/profile/"} className="text-decoration-none text-body">
            <Card className="dashboard-card bg-warning hover-zoom hover-shadow">
              <Card.Body className="d-flex align-items-center">
                <Suspense fallback={<span>Loading...</span>}>
                  {React.createElement(loadIcon("FaUser"), {
                    size: 40,
                    className: "me-3 text-primary",
                  })}
                </Suspense>
                <div>
                  <h5 className="text-dark">Profile</h5>
                  <p className="text-dark-50">My Profile</p>
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>

      {/* Charts and Insights */}
      <Row className="mb-4">
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Reading Summary</Card.Title>
              <Line data={chartData} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <Card.Title>What's new?</Card.Title>
              <ListGroup variant="flush">
                {insights.map((insight, index) => (
                  <ListGroup.Item key={index}>
                    <Link to={"/"}>{insight}</Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
