// /utils/loadIcon.js
import React from 'react';

const loadIcon = (iconName) => {
  if (!iconName) return () => <span>&#9733;</span>; // Fallback to a star icon if not found

  return React.lazy(() =>
    import('react-icons/fa').then((module) => ({
      default: module[iconName] || (() => <span>&#9733;</span>), // Fallback if the icon is not found
    }))
  );
};

export default loadIcon;
