// File path: /src/components/Footer.js

import React from "react";

const Footer = () => {
  // const currentYear = new Date().getFullYear();
  //  const startYear = 2016;
  //const displayYear =
  //  currentYear > startYear ? `${startYear} - ${currentYear}` : startYear;

  return <footer className="mt-2"></footer>;
};

export default Footer;
