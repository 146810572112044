// File: /src/context/processor.js

import axios from "axios";

/**
 * Axios instance for API requests with a default base URL and headers.
 */
export const apiData = axios.create({
  baseURL: "/query",
  headers: {
    "Content-Type": "multipart/form-data",
  }
});

/**
 * Axios request interceptor to add Authorization header.
 */
apiData.interceptors.request.use(
  (config) => {
    const token = localData("get", "session")?.token || null;
    if (token && token.length > 32) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(new Error(error.message));
  }
);

/**
 * Runs a POST request to the specified path with the provided data.
 * @param {Object} data - Data to be sent in the request.
 * @param {string} path - API endpoint path.
 * @param {string} [type] - Optional response type.
 * @returns {Promise} - Axios response promise.
 */
export async function runData(data, path, type) {
  const formData = new FormData();
  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      formData.append(key, data[key]);
    }
  }

  const config = type ? { responseType: type } : {};

  try {
    return await apiData.post(path, formData, config);
  } catch (error) {
    let errorMessage;
    let statusCode;

    if (type === "blob") {
      errorMessage =
        "An error occurred while processing your request, please contact support.";
    } else {
      errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred.";
      statusCode = error.response?.status || 402;
    }
    throw new Errored(errorMessage, statusCode);
  }
}

/**
 * Runs a GET request to the specified path with the provided data.
 * @param {Object} data - Data to be sent in the request.
 * @param {string} path - API endpoint path.
 * @param {string} [type] - Optional response type.
 * @returns {Promise} - Axios response promise.
 */
export async function getData(data, path, type) {
  const config = { params: data };
  if (type) {
    config.responseType = type;
  }

  try {
    return await apiData.get(path, config);
  } catch (error) {
    let errorMessage;
    let statusCode;

    if (type === "blob") {
      errorMessage =
        "An error occurred while processing your request, please contact support.";
    } else {
      errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred.";
      statusCode = error.response?.status || 402;
    }
    throw new Errored(errorMessage, statusCode);
  }
}

/**
 * Manages local storage actions.
 * @param {string} action - Action to perform: 'save', 'remove', 'get', 'update'.
 * @param {string} item - Item key.
 * @param {any} [value] - Value to save or update.
 * @returns {any} - The result of the action.
 */
export function localData(action, item, value) {
  switch (action) {
    case "save": {
      localStorage.setItem(item, JSON.stringify(value));
      return true;
    }
    case "remove": {
      const storedItem = localStorage.getItem(item);
      if (storedItem) {
        localStorage.removeItem(item);
        return true;
      }
      return false;
    }
    case "get": {
      const data = localStorage.getItem(item);
      return data ? JSON.parse(data) : null;
    }
    case "update": {
      const storedItem = localStorage.getItem(item);
      if (storedItem) {
        localStorage.setItem(item, JSON.stringify(value));
        return true;
      }
      return false;
    }
    default:
      return null;
  }
}

/**
 * Manages cart data actions.
 * @param {string} action - Action to perform: 'save', 'remove', 'get', 'check', 'update', 'increase', 'decrease'.
 * @param {string} item - Item key.
 * @param {any} [value] - Value to save, update, or modify.
 * @returns {any} - The result of the action.
 */
export function cartData(action, item, value) {
  let cart = JSON.parse(localStorage.getItem("cart") || "{}");

  switch (action) {
    case "save":
      cart[item] = value;
      localStorage.setItem("cart", JSON.stringify(cart));
      window.dispatchEvent(new Event("cartChange"));
      return true;
    case "remove":
      if (Object.hasOwn(cart, item)) {
        delete cart[item];
        localStorage.setItem("cart", JSON.stringify(cart));
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    case "get":
      return cart;
    case "check":
      return cart[item] || false;
    case "update":
      if (Object.hasOwn(cart, item)) {
        cart[item] = value;
        localStorage.setItem("cart", JSON.stringify(cart));
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    case "increase":
      if (Object.hasOwn(cart, item)) {
        cart[item].quantity += value;
        localStorage.setItem("cart", JSON.stringify(cart));
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    case "decrease":
      if (Object.hasOwn(cart, item) && cart[item].quantity > 1) {
        cart[item].quantity -= value;
        localStorage.setItem("cart", JSON.stringify(cart));
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    default:
      return false;
  }
}

export class Errored extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
  }
}
