// src/templates/Logout.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { localData, runData } from "../context/processor";
import { logout } from "../utils/auth";
import ErrorPage from "../includes/ErrorPage";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

/**
 * Logout component handles user logout and displays appropriate messages.
 * @component
 * @returns {JSX.Element} - The Logout component.
 */
const Logout = () => {
  const title = "Logout";
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const [error, setError] = useState(null);

  /**
   * Handles user logout process by calling the logout API.
   * @async
   * @function handleLogout
   * @returns {Promise<void>}
   */
  const handleLogout = async () => {
    setProcessing(true);
    const data = {
      token: localData("get", "session").token,
    };

    try {
      const responseData = await runData(data, `/logout/`);

      if (responseData.data) {
        setReport({
          show: false,
          message: responseData.data.message,
          type: "success",
        });
        logout();
      } else if (responseData.error) {
        setError(responseData.error.message);
        setReport({
          show: true,
          message: responseData.error.message,
          type: "danger",
        });
      } else {
        setError("There was an error!");
        setReport({
          show: true,
          message: "There was an error!",
          type: "danger",
        });
      }
    } catch (error) {
      setError("An unexpected error occurred.");
      setReport({
        show: true,
        message: "An unexpected error occurred.",
        type: "danger",
      });
    } finally {
      setProcessing(false);
    }
  };

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{title} - Care Quality Support</title>
      </Helmet>
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Row
          className="justify-content-center text-center p-5 rounded shadow bg-white"
          style={{ maxWidth: "600px" }}
        >
          <Col xs={12} className="mb-4">
            <h2 className="font-weight-bold">Do you want to logout?</h2>
            <p className="text-muted">
              You are about to logout. Do you want to stay logged in or log out?
            </p>
          </Col>
          <Col xs={6} className="mb-3">
            <Link to="/dashboard/">
              <Button
                className="btn btn-lg btn-green w-100 py-3"
                style={{ minWidth: "150px" }}
              >
                STAY LOGGED IN
              </Button>
            </Link>
          </Col>
          <Col xs={6} className="mb-3">
            <Button
              variant="danger"
              onClick={handleLogout}
              className="btn btn-lg btn-red w-100 py-3"
              style={{ minWidth: "150px" }}
            >
              LOGOUT NOW
            </Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Logout;
