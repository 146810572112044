import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import {
  FaEdit,
  FaDownload,
  FaTrash,
  FaShare,
  FaPaperclip,
  FaAngleLeft,
  FaPlus,
  FaMinus,
} from "react-icons/fa";
import { Link, NavLink, useParams } from "react-router-dom";

const DocumentWithAttachments = () => {
  const [document, setDocument] = useState(null);
  const [showDetails, setShowDetails] = useState(true);
  const [attachmentsExpanded, setAttachmentsExpanded] = useState(false);
  const params = useParams();
  let directoryPath = `${
    params.directory !== "directory" ? params.directory : ""
  }`;

  directoryPath =
    directoryPath && !directoryPath.endsWith("/")
      ? `${directoryPath}/`
      : directoryPath;

  useEffect(() => {
    // Simulate API call to fetch document data
    const fetchedDocument = {
      reference: "1",
      title: "Sample Document",
      content:
        "<p>This is a sample document with <strong>HTML content</strong> to display.</p><p>It contains paragraphs, bold text, and other HTML elements.</p>",
      attachments: [
        { name: "Attachment1.pdf" },
        { name: "Attachment2.docx" },
        { name: "Attachment3.xlsx" },
      ],
    };
    setDocument(fetchedDocument);
  }, []);

  const handleDelete = () => {
    // Simulate document deletion
    setDocument(null);
  };

  const handleAttachmentDownload = (attachment) => {
    // Simulate downloading attachment
    console.log("Downloading attachment:", attachment.name);
  };

  const handleAttachmentDelete = (attachment) => {
    // Simulate deleting attachment
    setDocument((prevDocument) => ({
      ...prevDocument,
      attachments: prevDocument.attachments.filter((att) => att !== attachment),
    }));
  };

  return (
    <Container fluid>
      <Row className="mb-4 align-items-center">
        <Col className="d-flex align-items-center">
          <NavLink
            to={`/directories/${directoryPath}`}
            className="d-flex align-items-center"
            style={{
              textDecoration: "none",
              color: "inherit",
              borderRight: "solid 2px red",
            }}
          >
            <FaAngleLeft style={{ marginRight: "0.5rem" }} /> Back
          </NavLink>
          <h4 className="ms-3 mb-0">Document Details</h4>
        </Col>
      </Row>
      <Row>
        <Col md={8} className="p-4 border-end">
          {document ? (
            <div
              className="document-content p-3 rounded"
              style={{ backgroundColor: "#ffffff" }}
            >
              <h4>{document.title}</h4>
              <div
                className="document-body mt-3"
                dangerouslySetInnerHTML={{ __html: document.content }}
              />
              {document.attachments && document.attachments.length > 0 && (
                <div className="attachments-section mt-5">
                  <Button
                    className="attachments-header d-flex justify-content-between align-items-center p-3 mb-4"
                    style={{
                      backgroundColor: "#f8f9fa",
                      border: "1px solid #ddd",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    onClick={() => setAttachmentsExpanded(!attachmentsExpanded)}
                  >
                    <h5 className="mb-0">Attachments</h5>
                    <span>
                      {attachmentsExpanded ? <FaMinus /> : <FaPlus />}
                    </span>
                  </Button>
                  <ul
                    className="list-group"
                    style={{ display: attachmentsExpanded ? "block" : "none" }}
                  >
                    {document.attachments.map((attachment) => (
                      <li
                        key={attachment.name}
                        className="list-group-item d-flex justify-content-between align-items-center mb-2"
                      >
                        <span>{attachment.name}</span>
                        <div>
                          <Button
                            size="sm"
                            variant="outline-success"
                            className="me-2"
                            title="Download Attachment"
                            onClick={() => handleAttachmentDownload(attachment)}
                          >
                            <FaDownload />
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-danger"
                            title="Delete Attachment"
                            onClick={() => handleAttachmentDelete(attachment)}
                          >
                            <FaTrash />
                          </Button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center">
              <h5>The document has been deleted</h5>
            </div>
          )}
        </Col>

        <Col
          md={4}
          className="p-4"
          style={{
            height: "70vh",
            position: "sticky",
            top: "70px",
            borderLeft: "1px solid #ccc",
          }}
        >
          {document && (
            <div className="details-section d-flex flex-column h-100">
              <div className="tabs-section mb-3 d-flex" style={{ gap: "1rem" }}>
                <Button
                  variant={showDetails ? "primary" : "outline-primary"}
                  onClick={() => setShowDetails(true)}
                  style={{ flex: 1 }}
                >
                  Details
                </Button>
                <Button
                  variant={!showDetails ? "primary" : "outline-primary"}
                  onClick={() => setShowDetails(false)}
                  style={{ flex: 1 }}
                >
                  Activity
                </Button>
                <Button variant="success" style={{ flex: 1 }}>
                  Mark As Read
                </Button>
              </div>
              <div className="details-content flex-grow-1 mb-3">
                {showDetails ? (
                  <div>
                    <p>
                      <strong>Title:</strong> {document.title}
                    </p>
                    <p>
                      <strong>Type:</strong> File
                    </p>
                    <p>
                      <strong>Content:</strong> A detailed description of the
                      document will go here...
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>Activity information will be shown here...</p>
                  </div>
                )}
              </div>
              <div
                className="action-buttons d-flex flex-wrap justify-content-between"
                style={{ marginTop: "auto", gap: "0.5rem" }}
              >
                <Button
                  size="sm"
                  variant="outline-info"
                  className="flex-grow-1 d-flex align-items-center justify-content-center"
                  title="Share Document"
                >
                  <FaShare />
                </Button>
                <Link
                to={`/directories/${params.directory}/${params.document}/editor/`}
                  size="sm"
                  className="bg-success flex-grow-1 btn d-flex align-items-center justify-content-center"
                  title="Edit Online"
                >
                  <FaEdit />
                </Link>
                <Button
                  size="sm"
                  variant="outline-success"
                  className="flex-grow-1 d-flex align-items-center justify-content-center"
                  title="Download Document"
                >
                  <FaDownload />
                </Button>
                <Button
                  size="sm"
                  variant="outline-warning"
                  className="flex-grow-1 d-flex align-items-center justify-content-center"
                  title="Add Attachment"
                >
                  <FaPaperclip />
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  className="flex-grow-1 d-flex align-items-center justify-content-center"
                  onClick={handleDelete}
                  title="Delete Document"
                >
                  <FaTrash />
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>

    </Container>
  );
};

export default DocumentWithAttachments;
