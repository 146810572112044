import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Modal,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";
import Office from "../assets/office.jpg";
import { runData } from "../context/processor";
import ErrorPage from "../includes/ErrorPage";
import Processing from "../includes/Processing";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

/**
 * Recover component allows users to reset their password.
 * @component
 * @returns {JSX.Element} - The Recover component.
 */
const Recover = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("Loading");
  const [error, setError] = useState(null);

  // Fetch page data for recovery
  const pageData = async () => {
    const data = {
      page: "CompanyRecover",
    };
    try {
      const responseData = await runData(data, `/web/pages/`);
      if (responseData.data) {
        if (responseData.data.code === 200) {
          setTitle(DOMPurify.sanitize(responseData.data.title));
        } else {
          setTitle("Error");
          setError(DOMPurify.sanitize(responseData.data.message));
        }
      } else if (responseData.error) {
        setTitle("Error");
        setError(DOMPurify.sanitize(responseData.error.message));
      } else {
        setTitle("Error");
        setError("Unknown Error!");
      }
    } catch (err) {
      setTitle("Error");
      setError(err.message || "Unknown Error!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    pageData();
  }, []);

  // Validate password requirements
  const isValidPassword = (password) => {
    return (
      password.length >= 8 &&
      password.length <= 32 &&
      !password.includes(" ") &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password) &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password)
    );
  };

  // Handle password input change
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    setIsValid({
      length: value.length >= 8,
      lowercase: /[a-z]/.test(value),
      uppercase: /[A-Z]/.test(value),
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
    });
  };

  // Handle password reset
  const handleRecover = async (event) => {
    event.preventDefault();
    if (isValidPassword(password)) {
      setProcessing(true);
      const data = {
        token: DOMPurify.sanitize(token),
        password: DOMPurify.sanitize(password),
      };

      try {
        const responseData = await runData(data, "/recover/");
        if (responseData.data) {
          setReport({
            show: true,
            message: DOMPurify.sanitize(responseData.data.message),
            type: "success",
          });
          if (responseData.data.code === 200) {
            setTimeout(() => navigate("/login/"), 5000);
          }
        } else if (responseData.error) {
          setReport({
            show: true,
            message: DOMPurify.sanitize(responseData.error.message),
            type: "error",
          });
        } else {
          throw new Error("Unexpected response structure");
        }
      } catch (err) {
        setReport({
          show: true,
          message: DOMPurify.sanitize(err.message),
          type: "error",
        });
      } finally {
        setProcessing(false);
      }
    } else {
      setReport({
        show: true,
        message: "Invalid password input, please try again!",
        type: "error",
      });
    }
  };

  const isAllValid = Object.values(isValid).every((val) => val === true);

  if (loading) {
    return <Processing />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <div
      className="recover-page"
      style={{
        backgroundImage: `url(${Office})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <title>{title} - Care Quality Support</title>
      </Helmet>

      {/* Recover Form Section */}
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="justify-content-center w-100">
          <Col md={8} lg={6}>
            <Card className="shadow-sm p-4">
              <Card.Body>
                <h3 className="mb-4 text-center">Set New Password</h3>
                <Form onSubmit={handleRecover}>
                  <Form.Group controlId="password" className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                  </Form.Group>

                  {/* Password validation list */}
                  <ul>
                    <li style={{ color: isValid.length ? "green" : "red" }}>
                      At least 8 characters
                    </li>
                    <li style={{ color: isValid.lowercase ? "green" : "red" }}>
                      At least one lowercase letter
                    </li>
                    <li style={{ color: isValid.uppercase ? "green" : "red" }}>
                      At least one uppercase letter
                    </li>
                    <li style={{ color: isValid.number ? "green" : "red" }}>
                      At least one number
                    </li>
                    <li
                      style={{ color: isValid.specialChar ? "green" : "red" }}
                    >
                      At least one special character
                    </li>
                  </ul>

                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={!isAllValid}
                  >
                    Set Password
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Recover;
