import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { runData } from "../context/processor";
import { setSession } from "../utils/auth";
import ErrorPage from "../includes/ErrorPage";
import Processing from "../includes/Processing";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import Office from "../assets/office.jpg";

const Login = () => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("Loading");
  const [error, setError] = useState(null);
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  useEffect(() => {
    const pageData = async () => {
      try {
        const data = { page: "CompanyLogin" };
        const response = await runData(data, `/web/pages/`);

        if (response.status === 200) {
          setTitle(response.data.title);
        } else {
          setTitle("Error");
          setError(response.data.message || "An error occurred while loading the page.");
        }
      } catch (err) {
        setTitle("Error");
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    pageData();
  }, []);

  // Handle login form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent page reload on form submission
    setProcessing(true);
    try {
      const data = {
        email,
        password,
      };
      const response = await runData(data, "/web/login/");

      if (response.status === 200) {
        const { timeout, session, slot, name, avatar } = response.data;
        setSession({ timeout, token: session, name: name, avatar: avatar }, timeout, slot);
        setReport({
          show: true,
          message: "You have been logged in successfully!",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message: response.data.message || "An error occurred during login.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "An unexpected error occurred during login.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  // Handle password reset submission
  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setShowResetModal(false);
    setProcessing(true);
    const data = {
      email: resetEmail,
    };

    try {
      const response = await runData(data, "/web/reset/");

      if (response.status === 200) {
        setReport({
          show: true,
          message: response.data.message || "Password reset instructions sent.",
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message: response.data.message || "An error occurred while resetting the password.",
          type: "danger",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "An unexpected error occurred while resetting the password.",
        type: "danger",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleResetEmailChange = (event) => {
    setResetEmail(event.target.value);
  };

  if (loading) {
    return <Processing />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <div
      className="login-page"
      style={{
        backgroundImage: `url(${Office})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <title>{title} - Care Quality Support</title>
      </Helmet>

      {/* Login Form Section */}
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="justify-content-center w-100">
          <Col md={8} lg={6}>
            <Card className="shadow-sm p-4">
              <Card.Body>
                <h3 className="mb-4 text-center">Account Login</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="email" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="password" className="mb-4">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Login
                  </Button>
                  <Button
                    variant="link"
                    className="w-100 mt-3"
                    onClick={() => setShowResetModal(true)}
                  >
                    Forgot Password?
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Password Reset Modal */}
      <Modal show={showResetModal} onHide={() => setShowResetModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Password Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handlePasswordReset}>
            <Form.Group controlId="resetEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={handleResetEmailChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3 w-100">
              Send Reset Instructions
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
